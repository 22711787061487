<template>
  <div class="employee-draggable-list" id="draggable-items">
    <!--    employee list -->

    <!--    TODO this should be a chip group? -->
    <v-slide-group show-arrows v-if="showSlideGroup">
      <draggable
        :group="{ name: 'eventsDragGroup', pull: 'clone', put: false }"
        v-model="employees"
        :sort="false"
        :clone="prepareNewEvent"
      >
        <template v-for="employee in employees">
          <v-slide-item
            v-slot:default="{ active, toggle }"
            :key="employee.name"
            disabled
          >
            <v-btn
              small
              class="mx-2 draggable-item"
              active-class="purple white--text"
              depressed
              rounded
              :color="employee.color"
              @click.prevent
            >
              {{ employee.name }}
            </v-btn>
          </v-slide-item>
        </template>
      </draggable>
    </v-slide-group>

    <!--    <div id="draggable-items">-->
    <!--      <v-btn-->
    <!--              small-->
    <!--              v-for="e in employees"-->
    <!--              class="draggable-item ma-1"-->
    <!--              v-bind:employee-name="e.name"-->
    <!--              v-bind:employee-color="e.color"-->
    <!--              :color="e.color"-->
    <!--      >{{ e.name }}-->
    <!--      </v-btn>-->
    <!--    </div>-->
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: { draggable },
  name: "EmployeeChips",
  mounted() {},
  data: () => {
    return { showSlideGroup: true };
  },
  computed: {
    employees() {
      return this.$store.getters.employees;
    },
  },
  methods: {
    // TODO extract this method somewhere
    prepareNewEvent(employee) {
      return {
        date: null,
        start: null,
        end: null,
        employeeName: employee.name,
      };
    },
    forceRerender() {
      // fix for firefox missing right arrow - force rerender of slide group with hide/show
      this.showSlideGroup = false;
      this.$nextTick(() => {
        this.showSlideGroup = true;
      });
    },
  },
  watch: {
    employees(newVal) {
      this.forceRerender();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
