import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
// @ts-ignore
import App from "./App.vue";

// @ts-ignore
import vuetify from "./plugins/vuetify";
import store from "./state/store";
import router from "./router";
import moment from "moment";

import 'whatwg-fetch'

require("../config/amplifyConfig");

Vue.config.productionTip = false;

moment.locale("pl");

new Vue({
  // @ts-ignore
  vuetify,
  store,
  router,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount("#app");

router.afterEach((to, from) => {
  // dataLayer.push({
  //   'event': "PageView",
  //   'page_title': to.name,
  //   'page_path': to.path,
  // });
  // @ts-ignore
  if (typeof ga !== 'undefined') {
    // @ts-ignore
    ga('set', 'page', to.path);
    // @ts-ignore
    ga('send', 'pageview');
  } else {
    console.log("ga google analytics not defined (only injected as postprocess in production build)");
  }

});
