<template>
  <div class="employee-manager px-3 py-5">
    <div class="mt-2 mx-3 title ">Zestawienie godzin - pracownicy</div>

    <v-simple-table class="ma-1 mb-5">
      <thead>
        <tr>
          <th></th>
          <th>{{ labelForMonth }}</th>
          <th v-for="weekNum in weekNumbers" :key="weekNum">tydz. {{ weekNum }} </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(emp, i) in employees"
          :key="emp.name"
          :class="{ 'highlight-row': i === selectedRow && 'emp' === selectedTable}"
          @click="selectRow(i, 'emp')"
        >
          <td class="font-weight-bold text-left">{{ emp.name }}</td>
          <td class="text-center font-weight-bold">
            {{ totalMonthHoursByEmployeeName[emp.name] ? totalMonthHoursByEmployeeName[emp.name] : 0 }}
          </td>
          <td v-for="weekNum in weekNumbers" :key="weekNum">
            {{ employeeHoursForWeek(weekNum, emp.name) }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <div class="mt-2 mx-3 title ">Zestawienie godzin - grupy</div>

    <v-simple-table class="ma-1 ">
      <thead>
        <tr>
          <th></th>
          <th>{{ labelForMonth }}</th>
          <th v-for="weekNum in weekNumbers" :key="weekNum">tydz. {{ weekNum }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(group, i) in groups"
          :key="group.name"
          :class="{ 'highlight-row': i === selectedRow && 'group' === selectedTable }"
          @click="selectRow(i, 'group')"
        >
          <td class="font-weight-bold text-left">{{ group.name }}</td>
          <td class="text-center font-weight-bold">
            {{ totalMonthHoursByGroupName[group.name] ? totalMonthHoursByGroupName[group.name] : 0 }}
          </td>
          <td v-for="weekNum in weekNumbers" :key="weekNum">
            {{ totalWeekHoursByGroupName(weekNum, group.name) }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-container class="pa-8">
      <v-row></v-row>
    </v-container>

    <!--      TODO checkboxy do chowania pracowników z widoku-->
  </div>
</template>

<script>
import { Event } from "@/data/event";
import { mapState } from "vuex";

import Vue from "vue";
import { DateHelper } from "@/data/dateHelper";
import { softRounding } from "@/util/Numbers";

export default Vue.extend({
  name: "DetailsTab",
  data: () => {
    return {
      selectedRow: 2,
      selectedTable: null,
    };
  },
  computed: {
    currentMonth() {
      return this.$store.state.currentMonth;
    },
    labelForMonth() {
      return DateHelper.labelForMonth(this.currentMonth);
    },
    weekNumbers() {
      return this.$store.getters.weekNumbers;
    },
    eventsByWeekNumAndGroupName() {
      return this.$store.getters.eventsByWeekNumAndGroupName;
    },
    groups() {
      return this.$store.state.groups;
    },
    eventsByWeekNumAndEmployeeName() {
      return this.$store.getters.eventsByWeekNumAndEmployeeName;
    },
    employees() {
      return this.$store.getters.employees;
    },
    eventsByDay() {
      return this.$store.state.eventsByDay;
    },
    eventsByEmployeeName() {
      return this.$store.getters.eventsByEmployeeName;
    },
    totalMonthHoursByEmployeeName() {
      return this.$store.getters.totalMonthHoursByEmployeeName;
    },
    totalMonthHoursByGroupName() {
      return this.$store.getters.totalMonthHoursByGroupName;
    },
    totalWeekHoursByGroupName() {
      return this.$store.getters.totalWeekHoursByGroupName;
    },
  },
  methods: {
    selectRow(row, table) {
      this.selectedRow = row;
      this.selectedTable = table;
    },
    employeeHoursForWeek(weekNum, empName) {
      // TODO P3 move to state & optimize
      let hours = this.eventsByWeekNumAndEmployeeName(weekNum, empName)
              .map((ev) => Event.durationHours(ev))
              .reduce((a, b) => a + b, 0);
      return softRounding(hours, 1);
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
th,
td {
  padding-left: 5px !important;
  padding-right: 5px !important;
  text-align: center !important;
}
.highlight-row {
  background: #ececec;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
