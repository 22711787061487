<template>
  <div class="d-print-none">
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="300" hide-overlay class="d-print-none">
        <v-card>
          <v-card-title>
            Opcje wydruku
          </v-card-title>
          <v-card-text>
            <h4>Wybierz grupy do wydruku:</h4>
            <v-checkbox v-for="groupName in groupNames" :key="groupName" v-model="printGroups" :label="groupName"
                        :value="groupName" style="height: 30px"></v-checkbox>

            <div class="mt-4">
              <i>Po kliknięciu "Drukuj" wyświetlą się opcje wydruku. Upewnij się, że orientacja wydruku jest pozioma.</i>
            </div>
          </v-card-text>

          <!--            EMPLOYEE MANAGER BUTTONS -->
          <v-card-actions class="ml-5 mr-5">
            <div class="flex-grow-1"></div>
            <v-btn color="blue darken-1" outlined text @click="print()">
              Drukuj
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>


  </div>
</template>

<script>
export default {
  components: {},
  props: ["show"],
  name: "PrintOptionsDialog",
  methods: {
    print() {
      this.dialog = false;
      setTimeout(() => window.print(), 500);
    },
  },
  computed: {
    groupNames() {
      return this.$store.state.groups.map((group) => group.name);
    },
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("closed");
      },
    },
    printGroups: {
      get() {
        return this.$store.state.printGroups;
      },
      set(val) {
        this.$store.commit("setPrintGroups", val);
      },
    },
  },
  data: () => {
    return {
      selectedGroups: [],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
