<template>
  <v-app>
    <v-app-bar app clipped-right>
      <v-toolbar-title class="headline">

        <div style="float: left" class="logo">
          <span>grafu</span>
          <span class="font-weight-light">.pl</span>
        </div>

        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on }">
            <span
              class="overline pl-4"
              v-on="on"
              style="text-decoration: underline"
            >
              {{ monthNumAsStringWithYear(currentMonth) }}
            </span>
          </template>
          <div class="pa-2" style="background-color: white">
            <v-btn
              block
              text
              v-for="(month, i) in navigationMonths"
              @click="chooseMonth(month)"
              :key="i"
            >
              {{ monthNumAsString(month) }}
            </v-btn>
          </div>
        </v-menu>

      </v-toolbar-title>

      <v-spacer class="d-print-none" />

      <v-card max-width="60%" flat>
        <EmployeeChips class="d-print-none" />
      </v-card>

      <v-spacer class="d-print-none" />

      <!--      TODO fade transition between those two: spinner/check-->

      <StateIndicatorSpinner
        :state="stateIndicatorState"
        class="d-print-none"
      />

      <v-menu offset-y :close-on-content-click="false" open-on-hover>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" class="ml-4 d-print-none">
            <span>{{ currentLocationName }}</span>
          </v-btn>
        </template>
        <LocationList />
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      hide-overlay
      mobile-break-point="500"
      right
      fixed
      v-model="drawerEmployees"
      app
      clipped
      width="30%"
      class="d-print-none"
    >
      <DetailsTab />
    </v-navigation-drawer>

    <v-content class="ma-5">
      <Calendar2Vuetify id="main-calendar" style="page-break-after: always" />

      <v-row style="margin-bottom: 100px; margin-top: 20px" class="d-print-none">
        <v-btn
          text
          outlined
          class="ml-5 mr-3 d-print-none"
          @click="printCalendar"
        >
          <span>Drukowanie</span>
        </v-btn>

        <v-btn
          text
          outlined
          class="mr-3 d-print-none"
          @click="employeeViewDialogShow = true"
        >
          <span>Link dla pracownika</span>
        </v-btn>

        <v-spacer class="d-print-none"></v-spacer>

        <v-btn
          text
          @click="employeeManagerPopup = true"
          outlined
          class="ml-5 mr-3 d-print-none"
        >
          <span>Pracownicy</span>
        </v-btn>
        <v-btn
          text
          @click="drawerEmployees = !drawerEmployees"
          outlined
          class="mr-5 d-print-none"
        >
          <span>Zestawienia</span>
        </v-btn>
      </v-row>
    </v-content>

    <!--    FULL PAGE DIALOGS-->

    <EmployeeManagerDialog
      :show="employeeManagerPopup"
      @closed="employeeManagerPopup = false"
    />

    <LocationPickDialog :show="showLocationPickDialog" />

    <PrintOptionsDialog
      :show="printOptionsDialogShow"
      @closed="printOptionsDialogShow = false"
    />

    <EmployeeViewDialog
      :show="employeeViewDialogShow"
      @closed="employeeViewDialogShow = false"
    />
  </v-app>
</template>

<script>
import DetailsTab from "./mainApp/DetailsTab";
import Calendar2Vuetify from "./calendar/Calendar2Vuetify";
import EmployeeChips from "./mainApp/EmployeeChips";
import StateIndicatorSpinner from "./mainApp/partials/StateIndicatorSpinner";
import EmployeeManagerDialog from "@/views/mainApp/EmployeeManagerDialog";
import LocationPickDialog from "@/views/mainApp/LocationPickDialog";
import LocationList from "@/views/mainApp/partials/LocationList";
import PrintOptionsDialog from "@/views/mainApp/partials/PrintOptionsDialog";

import _ from "lodash";
import apiClient from "../services/apiClient";
import moment from "moment";
import { DateHelper } from "@/data/dateHelper";
import { getUserId } from "@/services/authClient";
import EmployeeViewDialog from "@/views/mainApp/partials/EmployeeViewDialog";

export default {
  name: "PageMainApp",
  components: {
    EmployeeViewDialog,
    PrintOptionsDialog,
    DetailsTab,
    Calendar2Vuetify,
    EmployeeChips,
    StateIndicatorSpinner,
    EmployeeManagerDialog,
    LocationPickDialog,
    LocationList,
  },
  computed: {
    state() {
      return this.$store.state;
    },
    getLocation() {
      return this.$store.getters.getLocation;
    },
    currentLocationId() {
      return this.state.currentLocationId;
    },
    currentLocationName() {
      let location = this.getLocation(this.currentLocationId);

      if (location) {
        return location.name;
      }

      return null;
    },
    currentMonth() {
      return this.$store.state.currentMonth;
    },
    navigationMonths() {
      let currentMonth = moment().month() + 1;
      if (currentMonth) {
        return [currentMonth - 1, currentMonth, currentMonth + 1];
      }
      return null;
    },
    eventsByDay() {
      return this.state.eventsByDay;
    },
    groups() {
      return this.state.groups;
    },
    employees() {
      return this.state.employees;
    },
    dirty() {
      return this.state.dirty;
    },
    loading() {
      return this.state.loading;
    },
    shouldShowCheck() {
      return this.state.saveInProgress === false && this.dirty === false;
    },
    stateIndicatorState() {
      if (this.state.saveInProgress === true) {
        return "spinner";
      } else if (this.state.saveInProgress === false && this.dirty === false) {
        return "check";
      }
      return null;
    },
  },
  methods: {
    monthNumAsString(month) {
      return moment()
        .month(month - 1)
        .format("MMMM");
    },
    monthNumAsStringWithYear(month) {
      return DateHelper.labelForMonthWithYear(month);
    },
    chooseMonth(month) {
      console.log("chooseMonth", month);
      this.$store.dispatch("chooseMonth", month);
    },
    chooseLocation(locationId) {
      console.log("chooseLocation", locationId);
      this.$store.dispatch("chooseLocation", locationId);
    },
    printCalendar() {
      this.printOptionsDialogShow = true;
      // window.print();
    },
    async logout() {
      await apiClient.signOut();
      this.$router.push("/login");
    },
  },
  async mounted() {
    this.$store.commit("setUserId", await getUserId());
    this.$store.commit("setCanModify", true);
    this.$store.dispatch("fetchLocations");

    this.showLocationPickDialog = true;
  },
  data() {
    return {
      showLocationPickDialog: false,
      drawerEmployees: false,
      employeeManagerPopup: false,
      printOptionsDialogShow: false,
      employeeViewDialogShow: false,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

@media print {
  @page {
    size: A4 landscape;
    margin: 5mm 5mm 5mm 5mm;
  }

  html, body {
    height: 190mm;
    width: 287mm;
    overflow: hidden;
  }

  #main-calendar {
    width: 280mm;
    height: 177mm;
  }

  .v-toolbar {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .v-toolbar__content {
    height: 30px !important;
  }

  .v-content {
    padding: 16px 0 0 !important;
  }

  /* === */
  .v-toolbar__title {
    margin: 0 auto;
  }

  .logo {
    float: none;
  }

}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
