




























































































































































































































import { Component, Vue } from "vue-property-decorator";
import pricingConfig from "../../config/pricingConfig";
import gifplayer from "./util/gifplayer.vue";

@Component({
  components: {
    "gif-player": gifplayer
  }

})
export default class PageBuy extends Vue {
  publicPath = process.env.BASE_URL;

  monthPriceConfig = pricingConfig.monthPriceConfig;
  monthPriceConfigFake = pricingConfig.monthPriceConfigFake;
}
