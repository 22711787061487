import { Event } from "@/data/event";
import { Employee, Group } from "@/data/dataModel";
import _ from "lodash";
import { TimeHelper } from "@/data/timeHelper";

export class DayEvents {


  static HOURS_COMPARE_CACHE: {[index:string]: number} = {};

  static getSorted(
    dayEvents: Event[],
    employees: Employee[],
    groups: Group[]
  ): Event[] {
    // 3rd important - employee name
    let newVal = _.sortBy(dayEvents, (ev) => ev.employeeName);

    // 2nd importance - start hour
    newVal = newVal.sort((a, b) => {

      let HOURS_COMPARE_CACHE_LABEL = a.start + "-" + b.start;
      if (DayEvents.HOURS_COMPARE_CACHE[HOURS_COMPARE_CACHE_LABEL]) {
        return DayEvents.HOURS_COMPARE_CACHE[HOURS_COMPARE_CACHE_LABEL];
      }

      if (TimeHelper.timeToMoment(a.start).isBefore(TimeHelper.timeToMoment(b.start))) {
        let ret = -1;
        DayEvents.HOURS_COMPARE_CACHE[HOURS_COMPARE_CACHE_LABEL] = ret;
        return ret;
      } else if (TimeHelper.timeToMoment(a.start).isSame(TimeHelper.timeToMoment(b.start))) {
        let ret = 0;
        DayEvents.HOURS_COMPARE_CACHE[HOURS_COMPARE_CACHE_LABEL] = ret;
        return ret;
      } else {
        let ret = 1;
        DayEvents.HOURS_COMPARE_CACHE[HOURS_COMPARE_CACHE_LABEL] = ret;
        return ret;
      }
    });

    // 1st importance - group orderding
    newVal = newVal.sort((a, b) => {
      // @ts-ignore
      let aGroup = _.find(employees, (emp) => emp.name === a.employeeName)
        .group;
      // @ts-ignore
      let bGroup = _.find(employees, (emp) => emp.name === b.employeeName)
        .group;

      let aGroupIndex = _.findIndex(groups, (gr) => gr.name === aGroup);
      let bGroupIndex = _.findIndex(groups, (gr) => gr.name === bGroup);

      return aGroupIndex - bGroupIndex;
    });

    return newVal;
  }

  static collapseEvents(baseEvent: Event, dayEvents: Event[]): Event[] {
    // collapses events around baseEvent (for same employee only)

    let indexesToRemove: number[] = [];

    for (let i = 0; i < dayEvents.length; i++) {
      const event: Event = dayEvents[i];

      // skip self
      if (event === baseEvent) continue;

      // skip other employees
      if (event.employeeName !== baseEvent.employeeName) continue;

      if (Event.doEventsOverlap(event, baseEvent)) {
        // merge events

        baseEvent.start = TimeHelper.min(event.start, baseEvent.start);
        baseEvent.end = TimeHelper.max(event.end, baseEvent.end);

        indexesToRemove.push(i);
      }
    }

    if (indexesToRemove.length > 0) {
      dayEvents = dayEvents.filter(
        (ev: Event, index: number) => !indexesToRemove.includes(index)
      );
    }
    return dayEvents;
  }
}
