import { EventsByDay } from "@/state/store";
import moment from "moment";

export class MonthEvents {
  static initializeMonthViewDays(eventsByDay: EventsByDay, month: number) {
    // this will make sure each day in month
    // is present in EventsByDay

    let startDate = this.firstShownDay(month);
    let endDate = this.lastShownDay(month);

    for (
      let i = moment(startDate);
      i.isSameOrBefore(endDate);
      i.add(1, "day")
    ) {
      let date: string = i.format("YYYY-MM-DD");
      if (!(date in eventsByDay)) {
        eventsByDay[date] = [];
      }
    }
  }

  static getDisplayedWeekNumbers(month: number) {
    let startDate = this.firstShownDay(month);
    let endDate = this.lastShownDay(month);

    let weeks = [];

    for (
      let i = moment(startDate);
      i.isSameOrBefore(endDate);
      i.add(1, "week")
    ) {
      weeks.push(i.isoWeek());
    }

    return weeks;
  }

  static pickRelevantDays(
    eventsByDay: EventsByDay,
    month: number
  ): EventsByDay {
    let newEventsByDay: EventsByDay = {};

    let startDate = this.firstShownDay(month);
    let endDate = this.lastShownDay(month);

    for (let date in eventsByDay) {
      let dateMoment = moment(date);
      if (
        dateMoment.isSameOrAfter(startDate) &&
        dateMoment.isSameOrBefore(endDate)
      ) {
        newEventsByDay[date] = [...eventsByDay[date]];
      }
    }

    return newEventsByDay;
  }

  private static firstShownDay(month: number) {
    let startDate = moment()
      .month(month - 1)
      .startOf("month")
      .startOf("isoWeek");
    return startDate;
  }

  private static lastShownDay(month: number) {
    let endDate = moment()
      .month(month - 1)
      .endOf("month")
      .endOf("isoWeek");
    return endDate;
  }
}
