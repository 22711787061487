<template>
  <div class="d-print-none">
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="800" hide-overlay class="d-print-none">
        <v-card>
          <v-card-title>
            Link dla pracownika
          </v-card-title>
          <v-card-text>
            <div class="mt-4">
              By umożliwić pracownikom oglądanie grafiku, skopiuj i wyślij im poniższy link: <br /><br />

              <p style="text-align: center">
                <a :href="employeeViewLink">{{employeeViewLink}}</a>
              </p>

              Link można otworzyć na telefonie lub komputerze. <br /><br />
              <b>
                Grafik pracownika jest na bieżąco aktualizowany, więc możesz dalej wprowadzać zmiany.
              </b>

            </div>
          </v-card-text>

          <!--            EMPLOYEE MANAGER BUTTONS -->
        </v-card>
      </v-dialog>
    </v-row>


  </div>
</template>

<script>
import { getUserId } from "@/services/authClient";

export default {
  components: {},
  props: ["show"],
  name: "EmployeeViewDialog",
  methods: {
    async generateLink() {
      let userId = await getUserId();
      let locationIdPrefix = this.$store.state.currentLocationId.substring(0,8);

      let link = window.location.href;
      link = link.replace("app",
        "view/" + userId + "/" + locationIdPrefix + "/")

      return link;
    }
  },
  computed: {
    groupNames() {
      return this.$store.state.groups.map((group) => group.name);
    },
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("closed");
      },
    },
    printGroups: {
      get() {
        return this.$store.state.printGroups;
      },
      set(val) {
        this.$store.commit("setPrintGroups", val);
      },
    },
  },
  async mounted() {
    this.employeeViewLink = await this.generateLink();
  },
  data: () => {
    return {
      selectedGroups: [],
      employeeViewLink: "",
    };
  },
  watch: {
    async show() {
      this.employeeViewLink = await this.generateLink();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
