<template>
  <div class="hello">
    <EventConfigurationPopup ref="eventConfigurationPopup" />

    <!--    TODO highlight current day-->

    <!--  TODO   event selection highlight-->
    <!--    TODO numbers for weeks-->

    <v-calendar
      type="month"
      locale="pl"
      :value="calendarDate"
      :weekdays="[1, 2, 3, 4, 5, 6, 0]"
      :event-margin-bottom="2"
      :event-more="false"
    >
      <!--      :value & @input instead of v-model to allow custom setter -->

      <template v-slot:day="{ present, past, date, day, future, outside, month }">
        <div
          @click="clickOutsideEvents($event)"
          style="height: 100%; min-height: 60px; min-width: 50px"
        >
          <DayEventsPrint :date="date" :day="day" :outside="outside" :month="month" class="only-print"/>

          <draggable
            :group=" {
                  name: 'eventsDragGroup',
                put: !dayOutsideSubscription(date),
                }"
            :value="eventsByDay[date]"
            style="height: 100%; min-height: 60px;"
            :sort="false"
            @change="handleDayEventsChange($event, date)"
            class="d-print-none"
            :move="canModify"
          >
            <!-- this div is based on original v-calendar day rendering (copied from resulting html) -->
            <div
              v-for="(ev, i) in eventsByDay[date]"
              :key="i"
              :data-date="date"
              :data-event="i"
              :class="[
                getEventColor(ev),
                ev === activeEvent ? 'additional-class' : '',
              ]"
              class="v-event v-event-start v-event-end white--text"
              style="height: 20px; top:0px; margin-bottom: 2px; "
              @click.stop="clickEvent(ev)"
              @mouseover="hoverevent = ev"
              @mouseleave="hoverevent = null"
            >
              <div
                class="pl-1 d-print-none event-text"
                :class="{ 'font-weight-bold': ev === activeEvent }"
              >
                {{ ev === activeEvent ? " ‣ " : "" }} {{ ev.start }}-{{
                ev.end
                }}
                <span v-if="!employeeNameFilter">
                  {{
                  hoverevent == ev
                  ? ev.employeeName.substring(0, 5) + "..."
                  : ev.employeeName
                  }}
                </span>

                <div
                  style="float:right;"
                  class="pr-1 font-weight-bold"
                  v-if="hoverevent == ev && canModify()"
                  @click.stop="clickRemoveEvent(date, ev, i)"
                >
                  x
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </template>

      <template v-slot:day-label="{ day, date, present }">
        <div
          style="width: 100%; border-radius: 3px;"
          class="fill-height d-print-none "
          :class="[dayOutsideSubscription(date) ? 'red lighten-1' : '']"
        >
          <button
            type="button"
            class="v-btn v-btn--depressed v-btn--fab v-btn--round theme--light v-size--small"
            :class="[
              dayOutsideSubscription(date) ? 'red lighten-3' : '',
              isToday(date) ? 'grey lighten-3 text-underline' : '',
              present ? '' : 'transparent',
            ]"
          >
            <span
              class="v-btn__content"
              :class="[isToday(date) ? 'font-weight-bold' : '']"
            >
              {{ day }}
            </span>
          </button>
        </div>
      </template>
    </v-calendar>
  </div>
</template>

<script>
import EventConfigurationPopup from "../mainApp/partials/EventConfigurationPopup";

import draggable from "vuedraggable";
import moment from "moment";
import DayEventsPrint from "@/views/calendar/subcomponents/DayEventsPrint";
import _ from "lodash";

export default {
  props: ["employeeNameFilter"],
  components: {
    DayEventsPrint,
    EventConfigurationPopup,
    draggable,
  },
  name: "Calendar2Vuetify",
  computed: {
    activeEvent() {
      return this.$store.state.activeEvent;
    },
    groups() {
      return this.$store.state.groups;
    },
    eventsByDay() {
      let eventsByDay = this.$store.state.eventsByDay;
      if (this.employeeNameFilter) {
        eventsByDay = _.mapValues(eventsByDay, dayEvents => {
          return dayEvents.filter(event => event.employeeName === this.employeeNameFilter)
        })
      }
      return eventsByDay;
    },
    employeesByName() {
      return this.$store.getters.employeesByName;
    },
    getEventColor() {
      return this.$store.getters.getEventColor;
    },
    calendarDate() {
      let currentMonth = this.$store.state.currentMonth;

      return moment()
        .month(currentMonth - 1)
        .format("YYYY-MM-DD");
    },
  },
  mounted() {
    // elements not present in html template above, hack to add @click listeners
    var els = document.getElementsByClassName("v-calendar-weekly__day-label");
    for (var i = 0; i < els.length; i++) {
      els[i].addEventListener("click", this.clickOutsideEvents);
    }
  },
  data() {
    return {
      hoverevent: null,
    };
  },
  methods: {
    canModify() {
      return this.$store.state.canModify;
    },
    isToday(date) {
      return date === this.$store.state.currentDate;
    },
    dayOutsideSubscription(date) {
      let currentLocation = this.$store.getters.getCurrentLocation;

      if (!currentLocation) {
        return false;
      }

      // todo add 5 days grace period
      return date > currentLocation.paidTo;
    },
    clickOutsideEvents() {
      this.$store.commit("setActiveEventAndCollapsePrevious", null);
    },
    clickRemoveEvent(date, event, index) {
      this.$store.commit("removeEventFromDay", {
        date: date,
        event: event,
        index: index,
      });
      this.$store.commit("setActiveEventAndCollapsePrevious", null);
    },
    handleDayEventsChange(draggableChangeEvent, date) {
      if (draggableChangeEvent.removed != null) {
        let event = draggableChangeEvent.removed.element;
        let index = draggableChangeEvent.removed.oldIndex;

        this.$store.commit("removeEventFromDay", {
          date: date,
          event: event,
          index: index,
        });
      }

      if (draggableChangeEvent.added != null) {
        let event = draggableChangeEvent.added.element;
        let index = draggableChangeEvent.added.newIndex;

        this.$store.commit("addEventToDay", {
          date: date,
          event: event,
          newIndex: index,
        });

        this.$store.commit("setActiveEventAndCollapsePrevious", event);
      }
    },
    clickEvent: function(event) {
      if (this.canModify()) {
        this.$store.commit("setActiveEventAndCollapsePrevious", event);
      }
      // this.activeEvent = event;
      // this.$refs["eventConfigurationPopup"].show();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- TODO rewrite this to wrapper div in template (calendar - day slot) -->
<style>

.only-print {
  display: none;
}

@media print {
  .only-print {
    display: block;
  }
}

.text-underline {
  text-decoration: underline;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.v-calendar-weekly__day {
  min-height: 100px;
  height: auto;
}

.v-calendar-weekly__week {
  min-height: 100px;
  height: 100% !important;
}
</style>
