<template>
  <div class=" mr-1" style="margin-left: 2px">
    <div
      class="overline text-center font-weight-bold day-label"
      style="margin-bottom: 4px; margin-top: 5px; padding:0; height: 6px; line-height: 0.3rem !important"
    >
      <span v-if="!outside">
      -{{ day }}-
      </span>
      <span v-else>
      {{ day }}.{{month}}
      </span>
    </div>
    <div v-for="(ev, i) in eventsByDayForPrint[date]" class="print-only-event">
      <div
        style="word-break: keep-all; margin-right: 2px;"
        :style="{
                'font-size': getPrintFontSize(eventsByDayForPrint[date].length)+'px',
                'line-height': (getPrintFontSize(eventsByDayForPrint[date].length)+1)+'px'
              }"
      >

        <div style="display: inline-block">
          <span
            :class="[getTextColorClass(ev)]"
            :style="{
              'font-size': getPrintFontSize(eventsByDayForPrint[date].length)+'px',
              'line-height': (getPrintFontSize(eventsByDayForPrint[date].length)+1)+'px'
              }"
            style="margin-right: 1px"
          >◼</span>
          <b>{{ ev.start }}-{{ ev.end }}</b>

        </div>
        <div style="display: inline-block; margin-left: 2px">
          {{ trimNameIfContainsSurname(ev.employeeName) }}
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["date", "day", "outside", "month"],
  name: "DayEventsPrint",
  methods: {
    getPrintFontSize(howManyEvents) {
      // TODO P1 adjust based on number of weeks (feb vs march)

      if (howManyEvents <= 8) {
        return 11;
      }

      if (howManyEvents <= 10) {
        return 10;
      }

      if (howManyEvents <= 11) {
        return 9;
      }

      if (howManyEvents <= 12) {
        return 8;
      }

      return 6;
    },
    getTextColorClass(event) {
      let color = this.getEventColor(event);
      return color + "--text";
    },
    trimNameIfContainsSurname(name) {
      name = name.trim();

      let spacePosition = name.indexOf(" ");

      if (spacePosition > 0) {
        return name.substr(0, spacePosition + 2) + ".";
      }

      return name;

    },
  },
  computed: {
    eventsByDayForPrint() {
      return this.$store.getters.eventsByDayForPrint;
    },
    getEventColor() {
      return this.$store.getters.getEventColor;
    },
  },
  data: () => {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.v-outside .day-label {
  font-weight: normal !important;
}

.very-small-events-font {
  font-size: 8px !important;
  line-height: 9px !important;
}

.small-events-font {
  font-size: 9px !important;
  line-height: 10px !important;
}

.print-only-event {
  white-space: nowrap;
}
</style>
