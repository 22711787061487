var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hello"},[_c('EventConfigurationPopup',{ref:"eventConfigurationPopup"}),_c('v-calendar',{attrs:{"type":"month","locale":"pl","value":_vm.calendarDate,"weekdays":[1, 2, 3, 4, 5, 6, 0],"event-margin-bottom":2,"event-more":false},scopedSlots:_vm._u([{key:"day",fn:function(ref){
var present = ref.present;
var past = ref.past;
var date = ref.date;
var day = ref.day;
var future = ref.future;
var outside = ref.outside;
var month = ref.month;
return [_c('div',{staticStyle:{"height":"100%","min-height":"60px","min-width":"50px"},on:{"click":function($event){return _vm.clickOutsideEvents($event)}}},[_c('DayEventsPrint',{staticClass:"only-print",attrs:{"date":date,"day":day,"outside":outside,"month":month}}),_c('draggable',{staticClass:"d-print-none",staticStyle:{"height":"100%","min-height":"60px"},attrs:{"group":{
                name: 'eventsDragGroup',
              put: !_vm.dayOutsideSubscription(date),
              },"value":_vm.eventsByDay[date],"sort":false,"move":_vm.canModify},on:{"change":function($event){return _vm.handleDayEventsChange($event, date)}}},_vm._l((_vm.eventsByDay[date]),function(ev,i){return _c('div',{key:i,staticClass:"v-event v-event-start v-event-end white--text",class:[
              _vm.getEventColor(ev),
              ev === _vm.activeEvent ? 'additional-class' : '' ],staticStyle:{"height":"20px","top":"0px","margin-bottom":"2px"},attrs:{"data-date":date,"data-event":i},on:{"click":function($event){$event.stopPropagation();return _vm.clickEvent(ev)},"mouseover":function($event){_vm.hoverevent = ev},"mouseleave":function($event){_vm.hoverevent = null}}},[_c('div',{staticClass:"pl-1 d-print-none event-text",class:{ 'font-weight-bold': ev === _vm.activeEvent }},[_vm._v(" "+_vm._s(ev === _vm.activeEvent ? " ‣ " : "")+" "+_vm._s(ev.start)+"-"+_vm._s(ev.end)+" "),(!_vm.employeeNameFilter)?_c('span',[_vm._v(" "+_vm._s(_vm.hoverevent == ev ? ev.employeeName.substring(0, 5) + "..." : ev.employeeName)+" ")]):_vm._e(),(_vm.hoverevent == ev && _vm.canModify())?_c('div',{staticClass:"pr-1 font-weight-bold",staticStyle:{"float":"right"},on:{"click":function($event){$event.stopPropagation();return _vm.clickRemoveEvent(date, ev, i)}}},[_vm._v(" x ")]):_vm._e()])])}),0)],1)]}},{key:"day-label",fn:function(ref){
            var day = ref.day;
            var date = ref.date;
            var present = ref.present;
return [_c('div',{staticClass:"fill-height d-print-none ",class:[_vm.dayOutsideSubscription(date) ? 'red lighten-1' : ''],staticStyle:{"width":"100%","border-radius":"3px"}},[_c('button',{staticClass:"v-btn v-btn--depressed v-btn--fab v-btn--round theme--light v-size--small",class:[
            _vm.dayOutsideSubscription(date) ? 'red lighten-3' : '',
            _vm.isToday(date) ? 'grey lighten-3 text-underline' : '',
            present ? '' : 'transparent' ],attrs:{"type":"button"}},[_c('span',{staticClass:"v-btn__content",class:[_vm.isToday(date) ? 'font-weight-bold' : '']},[_vm._v(" "+_vm._s(day)+" ")])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }