import Api from '@aws-amplify/api';
import mockApiResponses from "./mockApiData/mockApiResponses";
import { SavedState } from "@/data/dataModel";
import _ from "lodash";
import { getIdToken, signIn, signOut } from "@/services/authClient";
import { Location } from "@/data/location";

import { centralMockConfig } from "../../config/mockConfig";
import { BackendApi } from "@/services/backendApi";
import CreateNewAccountRequest = BackendApi.CreateNewAccountRequest;
import OrderResponse = BackendApi.OrderResponse;
import UpdateLocationStateRequest = BackendApi.UpdateLocationStateRequest;
import ExtendSubscriptionRequest = BackendApi.ExtendSubscriptionRequest;

const mockApiMode = centralMockConfig;

export default {
  getAllLocations,
  updateLocation,
  createNewAccount,
  extendSubscription,
  signOut,
};

async function getAuthorizationHeader() {
  let idToken = await getIdToken();
  return "Bearer " + idToken;
}

async function createNewAccount(
  createNewAccountRequest: CreateNewAccountRequest
): Promise<OrderResponse> {
  if (mockApiMode) {
    return new Promise((resolve) => {
      resolve({
        orderId: "orderID",
        redirectUrl: "https://ssl.dotpay.pl/test_payment/",
      });
    });
  }

  console.log("createNewAccountRequest request", createNewAccountRequest);

  let createOrderResponse: OrderResponse = await Api.post(
    "grafikiApi",
    "/createNewAccount",
    {
      body: createNewAccountRequest,
    }
  );

  return createOrderResponse;
}

async function extendSubscription(
  extendSubscriptionRequest: ExtendSubscriptionRequest
): Promise<OrderResponse> {
  if (mockApiMode) {
    return new Promise((resolve) => {
      resolve({
        orderId: "orderID",
        redirectUrl: "https://ssl.dotpay.pl/test_payment/",
      });
    });
  }

  console.log("extendSubscriptionRequest request", extendSubscriptionRequest);

  let createOrderResponse: OrderResponse = await Api.post(
    "grafikiApi",
    "/extendSubscription",
    {
      headers: {
        Authorization: await getAuthorizationHeader(),
      },
      body: extendSubscriptionRequest,
    }
  );

  return createOrderResponse;
}

async function getAllLocations(userId: string): Promise<Location[]> {
  if (mockApiMode) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockApiResponses.locations);
      }, 10);
    });
  }

  try {
    let getAllLocations: Location[] = await Api.get(
      "grafikiApi",
      "/getAllLocations/" + userId,
      {}
    );
    console.log("getAllLocations response", getAllLocations);
    return getAllLocations;
  } catch (e) {
    console.log("Request failed", e, e.response);
    alert("BRAK POŁĄCZENIA");
    return [];
  }
}

let LAST_UPDATE_MS = performance.now();
let INACTIVITY_LIMIT_MS = 1000 * 60 * 60 * 3;

async function updateLocation(
  locationId: string,
  optimisticLockingVersion: number | null,
  savedState: SavedState | null,
  newName: string | null
): Promise<boolean> {

  let TIME_MS = performance.now();
  let inactivityPeriodMs = TIME_MS - LAST_UPDATE_MS;
  console.log("inactivity", inactivityPeriodMs);
  console.log("inacitvity limit", INACTIVITY_LIMIT_MS);

  if (inactivityPeriodMs > INACTIVITY_LIMIT_MS) {
    alert(
      "Rozłączono z powodu braku aktywności. Kliknij OK, aby połączyć ponownie."
    );
    window.location.reload(false);
  }

  LAST_UPDATE_MS = performance.now();


  if (mockApiMode) {
    let location = <Location>(
      _.find(mockApiResponses.locations, ["locationId", locationId])
    );

    if (savedState) {
      location.savedState.employees = [...savedState.employees];
      location.savedState.groups = [...savedState.groups];
      // merge those two:
      location.savedState.eventsByDay = {
        ...savedState.eventsByDay,
      };
    }

    if (newName) {
      location.name = newName;
    }

    return new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  }

  let updateLocationRequest: UpdateLocationStateRequest = {
    locationId: locationId,
  };

  if (optimisticLockingVersion) {
    console.log("optimisticLockVersion added", optimisticLockingVersion);
    updateLocationRequest.optimisticLockingVersion = optimisticLockingVersion;
  }

  if (savedState) {
    updateLocationRequest.mergeEventsByDay = savedState.eventsByDay;
    updateLocationRequest.overrideEmployees = savedState.employees;
    updateLocationRequest.overrideGroups = savedState.groups;
  }

  if (newName) {
    updateLocationRequest.overrideName = newName;
  }

  console.log("updateLocation request", updateLocationRequest);

  try {
    let response = await Api.patch("grafikiApi", "/updateLocationState", {
      headers: {
        Authorization: await getAuthorizationHeader(),
      },
      body: updateLocationRequest,
    });
    console.log(response);
    return true;
  } catch (e) {
    console.log("Request failed", e, e.response);
    if (e.response.status === 500 && e.response.data.errorMsg) {
      let errorMsg = e.response.data.errorMsg;
      console.log("Typed ErrorResponse", errorMsg);

      if (errorMsg === "WRONG OPTIMISTIC LOCKING VERSION") {
        alert(
          "Rozłączono. Upewnij się, że aplikacja nie jest otwarta w innym oknie i kliknij OK, by odświeżyć stronę."
        );
        window.location.reload(false);
      }
    }
    return false;
  }
}
