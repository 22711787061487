<template>
  <v-app>
    <v-app-bar app clipped-right>
      <v-toolbar-title class="headline" style="width: 100%">
        <div @click="$router.push('/')" style="float: left" class="logo">
          <span>grafu</span>
          <span class="font-weight-light">.pl</span>
        </div>

        <div style="float: right">
          <span
            class="overline pl-4"
            style="text-decoration: underline"
          >
        </span>
        </div>

      </v-toolbar-title>

    </v-app-bar>

    <v-content class="ma-5">
      <v-row class=" mb-5" align="center" justify="center">
        <span
          class="overline pl-4"
          style="width:100%; text-align:center;"
        >
          {{ currentLocationName }} <b>{{employeeNameFilter}}</b>
          <br/>

        <a @click="chooseMonth(monthNow)" v-bind:class="{boldStyle : currentMonth == monthNow}">
          {{ monthNumAsString(monthNow) }}</a> 
        |
        <a @click="chooseMonth(monthNow+1)" v-bind:class="{boldStyle : currentMonth == monthNow+1}">
          {{ monthNumAsString(monthNow+1) }}</a>


      </span>

      </v-row>


      <Calendar2Vuetify
        id="main-calendar"
        style="page-break-after: always"
        :employee-name-filter="employeeNameFilter"
        :write-permission="false" />
    </v-content>

    <!--    FULL PAGE DIALOGS-->

    <EmployeeChooseDialog @employeeChosen="employeeChosen($event)" location-employees="locationEmployees" />

  </v-app>
</template>

<script>
import DetailsTab from "./mainApp/DetailsTab";
import Calendar2Vuetify from "./calendar/Calendar2Vuetify";
import EmployeeChips from "./mainApp/EmployeeChips";
import StateIndicatorSpinner from "./mainApp/partials/StateIndicatorSpinner";
import EmployeeManagerDialog from "@/views/mainApp/EmployeeManagerDialog";
import LocationPickDialog from "@/views/mainApp/LocationPickDialog";
import LocationList from "@/views/mainApp/partials/LocationList";
import PrintOptionsDialog from "@/views/mainApp/partials/PrintOptionsDialog";
import apiClient from "../services/apiClient";
import moment from "moment";
import { DateHelper } from "@/data/dateHelper";
import EmployeeChooseDialog from "@/views/employeeView/EmployeeChooseDialog";

import _ from "lodash"

export default {
  name: "PageEmployeeView",
  props: ["userId", "locationIdPrefix"],
  components: {
    EmployeeChooseDialog,
    PrintOptionsDialog,
    DetailsTab,
    Calendar2Vuetify,
    EmployeeChips,
    StateIndicatorSpinner,
    EmployeeManagerDialog,
    LocationPickDialog,
    LocationList,
  },
  computed: {
    state() {
      return this.$store.state;
    },
    getLocation() {
      return this.$store.getters.getLocation;
    },
    currentLocationId() {
      return this.state.currentLocationId;
    },
    currentLocationName() {
      let location = this.getLocation(this.currentLocationId);

      if (location) {
        return location.name;
      }

      return null;
    },
    currentMonth() {
      return this.$store.state.currentMonth;
    },
    navigationMonths() {
      let currentMonth = moment().month() + 1;
      if (currentMonth) {
        return [currentMonth - 1, currentMonth, currentMonth + 1];
      }
      return null;
    },
    eventsByDay() {
      return this.state.eventsByDay;
    },
    groups() {
      return this.state.groups;
    },
    employees() {
      return this.state.employees;
    },
    dirty() {
      return this.state.dirty;
    },
    loading() {
      return this.state.loading;
    },
    shouldShowCheck() {
      return this.state.saveInProgress === false && this.dirty === false;
    },
    stateIndicatorState() {
      if (this.state.saveInProgress === true) {
        return "spinner";
      } else if (this.state.saveInProgress === false && this.dirty === false) {
        return "check";
      }
      return null;
    },
    locations() {
      return this.state.locations;
    },
    monthNow() {
      // "today" date, not changing
      return moment().month() + 1;
    },
  },
  methods: {
    employeeChosen(employeeName) {
      this.employeeNameFilter = employeeName;

    },
    monthNumAsString(month) {
      return moment()
        .month(month - 1)
        .format("MMMM");
    },
    monthNumAsStringWithYear(month) {
      return DateHelper.labelForMonthWithYear(month);
    },
    chooseMonth(month) {
      console.log("chooseMonth", month);
      this.$store.dispatch("chooseMonth", month);
    },
    chooseLocation(locationId) {
      console.log("chooseLocation", locationId);
      this.$store.dispatch("chooseLocation", locationId);
    },
    async logout() {
      await apiClient.signOut();
      this.$router.push("/login");
    },
  },
  async mounted() {
    console.log("userId", this.userId);
    console.log("locationIdPrefix", this.locationIdPrefix);

    this.$store.commit("setUserId", this.userId);

    await this.$store.dispatch("fetchLocations");

    let location = _.find(this.locations, location => location.locationId.startsWith(this.locationIdPrefix));

    if(!location) {
      alert("Błędny link");
    }

    await this.$store.dispatch("chooseLocation", location.locationId);

    // TODO !! show dialog - choose emp, choose month (show spinner)
    // TDOO !! --> choose location based on URL

  },
  data() {
    return {
      showLocationPickDialog: false,
      drawerEmployees: false,
      employeeManagerPopup: false,
      printOptionsDialogShow: false,
      employeeNameFilter: " ",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

@media print {
  @page {
    size: A4 landscape;
    margin: 5mm 5mm 5mm 5mm;
  }

  html, body {
    height: 190mm;
    width: 287mm;
    overflow: hidden;
  }

  #main-calendar {
    width: 280mm;
    height: 177mm;
  }

  .v-toolbar {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .v-toolbar__content {
    height: 30px !important;
  }

  .v-content {
    padding: 16px 0 0 !important;
  }

  /* === */
  .v-toolbar__title {
    margin: 0 auto;
  }

  .logo {
    float: none;
  }

}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.boldStyle {
  font-weight: bold;;
}
</style>
