






















































































































import apiClient from "../services/apiClient";
import { Component, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DiscountCode } from "@/data/discountCode";
import moment from "moment";
import pricingConfig from "../../config/pricingConfig";
import { AMPLIFY_CONFIG } from "../../config/amplifyConfig";
import { BackendApi } from "@/services/backendApi";

@Component
export default class PageBuy extends Vue {
  // TODO R1 10% discount for 6 month subscription
  email: string = "";
  emailError: boolean = false;
  emailMsg: string = "";

  howManyMonths: number = 2;
  howManyLocations: number = 1;
  createOrderInProgress: boolean = false;

  monthPrice: number = pricingConfig.monthPriceConfig;
  discount: number = 0;

  showDiscountForm: boolean = false;
  discountCodeInput: string = "";
  discountCodeSuccess: boolean = false;
  discountCodeError: boolean = false;
  discountCodeMsg: string = "";
  debounceValidateDiscountCode = _.debounce(this.validateDiscountCode, 500);

  locationsOption = [
    { text: "1 lokal", value: 1 },
    { text: "2 lokale", value: 2 },
    { text: "3 lokale", value: 3 },
  ];

  get totalWithDiscount() {
    let total = this.totalPreDiscount - this.discount;
    return total >= 0 ? total : 0;
  }

  get totalPreDiscount() {
    return this.howManyLocations * this.howManyMonths * this.monthPrice;
  }

  validateDiscountCode() {
    this.discountCodeSuccess = false;
    this.discountCodeError = false;
    this.discountCodeMsg = "";

    this.discountCodeInput = this.discountCodeInput.trim();

    if (!DiscountCode.validateChecksum(this.discountCodeInput)) {
      this.discount = 0;

      this.discountCodeSuccess = false;
      this.discountCodeError = true;
      this.discountCodeMsg = "Kod nie jest prawidłowy";
      return;
    }

    if (!DiscountCode.isStillActive(this.discountCodeInput, moment())) {
      this.discount = 0;

      this.discountCodeSuccess = false;
      this.discountCodeError = true;
      this.discountCodeMsg = "Kod jest już nieważny";
      return;
    }

    this.discount = DiscountCode.getDiscountAmount(this.discountCodeInput);

    this.discountCodeSuccess = true;
    this.discountCodeError = false;
    this.discountCodeMsg = "Kod prawidłowy! Rabat " + this.discount + "zł";
  }

  monthsLabel(howManyMonths: number) {
    if (howManyMonths === 1) return "1 miesiąc";
    if (howManyMonths === 2) return "2 miesiące";
    if (howManyMonths === 6) return "6 miesięcy";

    return howManyMonths + " miesiące";
  }

  emailValid() {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.email.toLowerCase());
  }

  async createOrder() {
    this.email = this.email.trim();
    if (!this.emailValid()) {
      this.emailError = true;
      this.emailMsg = "Sprawdź adres email";

      return;
    }

    this.emailError = false;
    this.emailMsg = "";

    this.createOrderInProgress = true;

    let extendLocations: BackendApi.ExtendLocation[] = [];

    _.times(this.howManyLocations, () => {
      extendLocations.push({
        locationNameIfNew: "Nazwa lokalu",
        howManyMonths: this.howManyMonths,
      });
    });

    let thanksRoute = this.$router.resolve({ name: "PageThanks" });

    let thanksUrl = window.location.origin + "/" + thanksRoute.href;

    // dotpay redirect with POST method, therefore redirect goes to the server
    // server will respond with 303 and location thanksUrl
    let serverBaseUrl = AMPLIFY_CONFIG.API.endpoints[0].endpoint;
    let serverEndpoint = "/dotpayRedirect";
    let redirectQuery = "?redirectUrl=" + thanksUrl;

    let redirectSuccessUrl = serverBaseUrl + serverEndpoint + redirectQuery;

    try {
      let createOrderResponse: BackendApi.OrderResponse = await apiClient.createNewAccount({
        email: this.email,
        totalPrediscount: this.totalPreDiscount,
        discount: this.discount,
        discountCode: this.discountCodeInput,
        extendLocations: extendLocations,
        successRedirectUrl: redirectSuccessUrl,
      });

      if (createOrderResponse.redirectUrl != null) {
        window.location.href = createOrderResponse.redirectUrl;
      }
    } catch (e) {
      this.createOrderInProgress = false;
      console.log(e.response);
      let errorResponse = e.response.data as BackendApi.ErrorResponse;
      if (errorResponse.errorMsg === "USERNAME EXISTS") {
        alert(
          "Ten email jest już zarejstrowany.\nHasło nowego konta powinno przyjść na Twoją skrzynkę.\n" +
          "Jeżeli nie dostałeś hasła, skontaktuj się z nami info@grafu.pl",
        );
      } else {
        alert("Błąd podczas tworzenia zamówienia. Skontaktujemy się z Tobą wkrótce");
      }
    }
  }
}
