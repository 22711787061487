import moment, { Moment } from "moment";

export class TimeHelper {
  static max(time1: string, time2: string): string {
    let moment1 = this.timeToMoment(time1);
    let moment2 = this.timeToMoment(time2);

    if (moment1.isSameOrAfter(moment2)) {
      return this.momentToTime(moment1);
    }
    return this.momentToTime(moment2);
  }

  static min(time1: string, time2: string): string {
    let moment1 = this.timeToMoment(time1);
    let moment2 = this.timeToMoment(time2);

    if (moment1.isSameOrBefore(moment2)) {
      return this.momentToTime(moment1);
    }
    return this.momentToTime(moment2);
  }

  static ISBEFORE_CACHE: {[label: string]: boolean} = {};

  static is1Before2(time1: string, time2: string): boolean {
    let CACHE_LABEL = time1 + "-" + time2;

    if (TimeHelper.ISBEFORE_CACHE[CACHE_LABEL]) {
      return TimeHelper.ISBEFORE_CACHE[CACHE_LABEL];
    }

    let moment1 = this.timeToMoment(time1);
    let moment2 = this.timeToMoment(time2);


    let result = moment1.isSameOrBefore(moment2);
    TimeHelper.ISBEFORE_CACHE[CACHE_LABEL] = result;

    return result;
  }


  static sortHours(hours: string[]): string[] {

    return hours.sort((a,b) => {
      if (this.is1Before2(a,b)) {
        return -1;
      }
      return +1;
    })
  }

  static momentToTime(moment1: Moment) {
    if (moment1.minutes() === 0) {
      return moment1.format("H");
    }
    return moment1.format("H:mm");
  }

  static timeToMoment(time: string) {
    let mom = moment(time, "H:mm");
    return mom;
  }
}
