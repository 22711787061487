



export function softRounding(x: number, decimals: number) {

  if (!x) {
    x = 0;
  }

  return Number(Number(x).toFixed(decimals));

}
