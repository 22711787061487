<template>
  <!--    <transition name="fade">-->
  <!--        <component v-bind:is="view"></component>-->
  <!--    </transition>-->
  <div style="width: 30px">
    <v-icon class="ml-3 mr-3" color="green" v-if="state === 'check'">
      mdi-check
    </v-icon>
    <v-progress-circular
      v-if="state === 'spinner'"
      class="ml-3 mr-4"
      color="amber"
      indeterminate
      size="12"
      width="2"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "StateIndicatorSpinner",
  props: {
    state: String,
  },
  computed: {
    // view() {
    //   if(this.state) {
    //     return 'check';
    //   }
    //   return 'spinner';
    // },
  },
  components: {
    // 'check': {
    //   template: '<v-icon class="ml-3 mr-3" color="green" >mdi-check</v-icon>'
    // },
    // 'spinner': {
    //   template: '<v-progress-circular class="ml-3 mr-4" color="amber" indeterminate size="12" width="2" ></v-progress-circular>'
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
