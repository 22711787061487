





















































































































































import apiClient from "../services/apiClient";
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import { Location } from "@/data/location";
import pricingConfig from "../../config/pricingConfig";
import { BackendApi } from "@/services/backendApi";
import OrderResponse = BackendApi.OrderResponse;
import ExtendLocation = BackendApi.ExtendLocation;
import { AMPLIFY_CONFIG } from "../../config/amplifyConfig";
import { getUserId } from "@/services/authClient";

@Component
export default class PageExtend extends Vue {
  howManyMonths: number = 2;
  createOrderInProgress: boolean = false;

  monthPrice: number = pricingConfig.monthPriceConfig;
  discount: number = 0;

  newLocations: { name: string }[] = [];
  existingLocations: Location[] = [];
  locationIdsToExtend = new Set<string>();

  async created() {
    this.$store.commit("setUserId", await getUserId());
    await this.$store.dispatch("fetchLocations");

    this.existingLocations = this.$store.state.locations;
  }

  extendLocation(location: Location, shouldAdd: boolean) {
    if (shouldAdd) {
      this.locationIdsToExtend.add(location.locationId);
    } else {
      this.locationIdsToExtend.delete(location.locationId);
    }
    this.locationIdsToExtend = new Set(this.locationIdsToExtend);
  }

  isExtendLocation(location: Location) {
    return this.locationIdsToExtend.has(location.locationId);
  }

  addNewLocation() {
    this.newLocations.push({
      name: "nazwa lokalu",
    });
  }

  subscriptionDateLimitForExistingLocation(location: Location) {
    let currentLimit = Location.paidToMoment(location);
    if (currentLimit.isBefore(moment())) {
      currentLimit = moment();
    }
    return currentLimit.add(this.howManyMonths, "months").format("DD.MM");
  }

  subscriptionDateLimitForNewLocations() {
    let dateLimit = moment().add(this.howManyMonths, "months");
    return dateLimit.format("DD.MM");
  }

  monthsLabel(howManyMonths: number) {
    if (howManyMonths === 1) return "1 miesiąc";
    if (howManyMonths === 2) return "2 miesiące";
    else if (howManyMonths === 6) return "6 miesięcy";
    else return howManyMonths + " miesiące";
  }

  get total() {
    let locations = this.newLocations.length + this.locationIdsToExtend.size;
    return locations * this.howManyMonths * this.monthPrice - this.discount;
  }

  get locations() {
    return this.$store.state.locations;
  }

  paidToFormat(date: string) {
    return moment(date).format("DD.MM");
  }

  async createOrder() {
    this.createOrderInProgress = true;

    let extendLocations: ExtendLocation[] = [];

    this.newLocations.forEach((newLoc) => {
      extendLocations.push({
        howManyMonths: this.howManyMonths,
        locationNameIfNew: newLoc.name,
        locationIdIfExisting: "",
      });
    });

    this.locationIdsToExtend.forEach((id) => {
      extendLocations.push({
        howManyMonths: this.howManyMonths,
        locationIdIfExisting: id,
      });
    });

    let thanksRoute = this.$router.resolve({ name: "PageThanks" });

    let thanksUrl = window.location.origin + "/" + thanksRoute.href;

    // dotpay redirect with POST method, therefore redirect goes to the server
    // server will respond with 303 and location thanksUrl
    let serverBaseUrl = AMPLIFY_CONFIG.API.endpoints[0].endpoint;
    let serverEndpoint = "/dotpayRedirect";
    let redirectQuery = "?redirectUrl=" + thanksUrl;

    let redirectSuccessUrl = serverBaseUrl + serverEndpoint + redirectQuery;

    let createOrderResponse: OrderResponse = await apiClient.extendSubscription(
      {
        totalPrediscount: this.total,
        discount: 0,
        extendLocations: extendLocations,
        successRedirectUrl: redirectSuccessUrl,
      }
    );

    if (createOrderResponse.redirectUrl != null) {
      window.location.href = createOrderResponse.redirectUrl;
    }
  }
}
