import { sha256 } from "js-sha256";
import moment from "moment";

export class DiscountCode {
  private static parseDiscountCode(token: string) {
    // randomstring-YYDDDPP-CC
    // avocado-1933050-CC
    // YY - year
    // DDD - day of year (1-366)
    // PP - price discount
    // CC - checksum of everything up to checksum

    let pttrn = /^.+-(\d{2})(\d{3})-(\d{2})(.{2})$/;

    let match = pttrn.exec(token);
    console.log("match", match);
    return match;
  }

  static validateChecksum(token: string): boolean {
    let match = this.parseDiscountCode(token);

    if (match == null) {
      return false;
    }

    let checksumGiven = match[4];

    let stringForComputingChecksum = token.substring(0, token.length - 2);

    let checksumComputed = sha256(stringForComputingChecksum).substring(0, 2);

    console.log(
      "given checksum / calculated checksum2",
      checksumGiven,
      checksumComputed
    );

    return checksumGiven === checksumComputed;
  }

  static isStillActive(token: string, today: moment.Moment): boolean {
    let match = this.parseDiscountCode(token);

    if (match == null) {
      return false;
    }

    let year = Number(match[1]) + 2000;
    let dayOfYear = Number(match[2]);

    console.log("given year, dayOfYear", year, dayOfYear);

    let moment1 = moment()
      .year(year)
      .dayOfYear(dayOfYear);

    console.log("moment of discount Code", moment1);

    return today.isSameOrBefore(moment1);
  }

  static getDiscountAmount(token: string): number {
    let match = this.parseDiscountCode(token);

    if (match == null) {
      return 0;
    }

    return Number(match[3]);
  }
}
