<template>
  <!--    popup for choosing start/end time in month view-->
  <!--  TODO hide popup on enter-->
  <div class="d-print-none">
    <v-card
      v-if="activeEvent != null"
      style="position: fixed; bottom: 10px; z-index: 10; left:10px; width: 400px;"
    >
      <!--        TODO R1 text field - prepend icon clock-->
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Start pracy"
              placeholder="Placeholder"
              outlined
              v-model="start"
              :rules="hourInputRules"
            ></v-text-field>
            <div style="padding-left: 15px" class="overline">
              Podpowiedzi:
              <a v-for="hour in mostCommonStartHours" @click="start = hour" class="hourSuggestion">{{hour}}</a>
            </div>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Koniec pracy"
              placeholder="Placeholder"
              outlined
              v-model="end"
              :rules="hourInputRules"
            ></v-text-field>
            <div style="padding-left: 15px" class="overline">
              Podpowiedzi:
              <a v-for="hour in mostCommonEndHours" @click="end = hour" class="hourSuggestion">{{hour}}</a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <!--    <v-bottom-sheet-->
    <!--      persistent-->
    <!--      v-model="showSheet"-->
    <!--      inset-->
    <!--      :hide-overlay="true"-->
    <!--      max-width="300px"-->
    <!--      @click:outside="popupClosed"-->
    <!--    >-->
    <!--      <v-sheet class="text-center">-->
    <!--        <v-container>-->
    <!--          <v-row>-->
    <!--            <v-col cols="6">-->
    <!--              <v-text-field-->
    <!--                label="Start"-->
    <!--                placeholder="Placeholder"-->
    <!--                outlined-->
    <!--                v-model="start"-->
    <!--              ></v-text-field>-->
    <!--            </v-col>-->
    <!--            <v-col cols="6">-->
    <!--              <v-text-field-->
    <!--                label="Koniec"-->
    <!--                placeholder="Placeholder"-->
    <!--                outlined-->
    <!--                v-model="end"-->
    <!--              ></v-text-field>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--        </v-container>-->
    <!--      </v-sheet>-->
    <!--    </v-bottom-sheet>-->
  </div>
</template>

<script>
import { Event } from "@/data/event";

export default {
  name: "EventConfigurationPopup",
  data() {
    return {
      showSheet: false,
      hourInputRules: [
              input => Event.isValidHour(input) || "Format: 12 lub 12:30"
      ],
      x: [1,2,3],
    };
  },
  computed: {
    mostCommonStartHours() {
      return this.$store.getters.mostCommonStartHours;
    },
    mostCommonEndHours() {
      return this.$store.getters.mostCommonEndHours;
    },
    store() {
      return this.$store;
    },
    activeEvent() {
      return this.store.state.activeEvent;
    },
    start: {
      get: function() {
        if (this.activeEvent != null) {
          return this.activeEvent.start;
        }
        return "";
      },
      set: function(newVal) {
        // TODO R1 check newVal is before/after other hour
        if (Event.isValidHour(newVal)) {
          this.store.commit("activeEventUpdate", {
            start: newVal,
          });
        } else {
          console.log("Invalid hour ", newVal);
        }
      },
    },
    end: {
      get: function() {
        if (this.activeEvent != null) {
          return this.activeEvent.end;
        }
        return "";
      },
      set: function(newVal) {
        // TODO R1 check newVal is before/after other hour
        if (Event.isValidHour(newVal)) {
          this.store.commit("activeEventUpdate", {
            end: newVal,
          });
        } else {
          console.log("Invalid hour ", newVal);
        }
      },
    },
  },

  methods: {
    show() {
      this.showSheet = true;
    },
    setStart(hour) {
      this.start = hour;
    },
    setEnd(hour) {
      this.end = hour;
    },
    popupClosed() {
      // this.store.commit('setActiveEventAndCollapsePrevious', null)
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .hourSuggestion {
    padding: 0 4px;
  }
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
