import { SavedState } from "@/data/dataModel";
import moment from "moment";

export class Location {
  userId!: string;
  locationId!: string;
  savedState!: SavedState;
  name!: string;

  paidTo!: string;
  version!: number;

  static paidToMoment(location: Location): moment.Moment {
    return moment(location.paidTo, "YYYY-MM-DD");
  }
}
