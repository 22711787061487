<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialog" width="80%">
        <v-card>
          <v-card-title>
            Pracownicy
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                lg="4"
                md="6"
                sm="12"
                v-for="(group, i) in groups"
                :key="i"
              >
                <v-card
                  height="100%"
                  min-height="150"
                  class="mx-auto"
                  outlined
                  @mouseover="hoverGroup = group.name"
                  @mouseleave="hoverGroup = null"
                >
                  <v-card-title align="top">
                    <span v-if="editedGroup !== group.name">
                      {{ group.name }}
                    </span>
                    <div v-else style="max-width: 60%">
                      <v-text-field
                        append-outer-icon="mdi-check"
                        @click:append-outer="
                          changeGroupName(editedGroup, editedGroupInput)
                        "
                        v-model="editedGroupInput"
                        class="pt-0 mt-0"
                        label="Nazwa grupy"
                        single-line
                        hide-details
                      ></v-text-field>
                    </div>

                    <v-spacer />

                    <!--                    GROUP EDIT BUTTONS -->
                    <v-icon
                      v-if="hoverGroup === group.name"
                      style="vertical-align: text-top;"
                      class="ml-2"
                      size="18"
                      @click="editedGroup = editedGroupInput = group.name"
                    >
                      mdi-pencil-outline
                    </v-icon>
                    <v-icon
                      v-if="hoverGroup === group.name"
                      class="ml-2"
                      size="18"
                      @click="removeGroup(group.name)"
                    >
                      mdi-trash-can-outline
                    </v-icon>

                    <!-- color editor button -->
                    <v-icon
                      @click="colorEditorDialogOpen(group.name)"
                      class="ml-3"
                      :color="group.color"
                      style="float:right;"
                    >
                      mdi-checkbox-blank-circle
                    </v-icon>
                  </v-card-title>

                  <v-card-text>
                    <!--                      EMPLOYEE CHIPS PER GROUP-->
                    <div
                      v-for="(emp, i) in employeesByGroupName[group.name]"
                      :key="i"
                    >
                      <v-chip
                        @mouseover="hoverEmployee = emp.name"
                        @mouseleave="hoverEmployee = null"
                        class="ma-1"
                      >
                        {{ emp.name }}
                        <v-icon
                          v-if="hoverEmployee === emp.name && false"
                          class="ml-2"
                          size="15"
                          @click="employeeDialogStartEdit(emp)"
                        >
                          mdi-pencil-outline
                        </v-icon>
                        <v-icon
                          v-if="hoverEmployee === emp.name"
                          class="ml-1"
                          size="15"
                          @click="removeEmployee(emp.name)"
                        >
                          mdi-trash-can-outline
                        </v-icon>
                      </v-chip>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

          <!--            EMPLOYEE MANAGER BUTTONS -->
          <v-card-actions class="ml-5 mr-5">
            <v-btn color="blue darken-1" outlined text @click="addNewGroup">
              Dodaj nową grupę
            </v-btn>

            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="employeeDialogStartAdd"
            >
              Dodaj pracownika
            </v-btn>
            <div class="flex-grow-1"></div>
            <v-btn color="blue darken-1" outlined text @click="dialog = false">
              Zamknij
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog v-model="employeeDialog" width="500">
      <v-card>
        <v-card-title class="headline" primary-title>
          {{
            employeeDialogIsAddingNewOne
              ? "Nowy pracownik"
              : "Edytuj pracownika"
          }}
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                label="Imię"
                v-model="employeeDialogNameInput"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="employeeDialogGroupSelect"
                :items="groupNames"
                placeholder="Grupa"
              />
            </v-col>
          </v-row>
          <b>
            {{ employeeDialogErrorMsg }}
          </b>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="primary" text @click="employeeDialogConfirm">
            {{ employeeDialogIsAddingNewOne ? "Dodaj" : "Zmień" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="180" value="true" v-model="colorEditorDialog">
      <v-card align="center">
        <v-card-title>
          Wybierz kolor
        </v-card-title>
        <v-card-text>
          <v-icon
            :color="color"
            :key="color"
            class="ma-3"
            v-for="color in colors"
            @click="changeGroupColor(color)"
          >
            mdi-checkbox-blank-circle
          </v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  name: "EmployeeManagerDialog",
  props: ["show"],
  computed: {
    groupNames() {
      return this.$store.state.groups.map((group) => group.name);
    },
    employeesByGroupName() {
      return this.$store.getters.employeesByGroupName;
    },
    groups() {
      return this.$store.state.groups;
    },
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("closed");
      },
    },
    employeesByName() {
      return this.$store.getters.employeesByName;
    },
  },
  data: () => {
    return {
      colorEditorDialog: false,
      colors: [
        "green", "lime", "blue", "cyan", "orange", "red", "purple", "brown",
      ],
      colorEditGroup: "kuchnia",
      editedGroup: null,
      editedGroupInput: "",
      hoverGroup: null,
      hoverEmployee: null,

      // employee add/edit
      employeeDialog: false,
      employeeDialogGroupSelect: null,
      employeeDialogNameInput: "",
      employeeDialogIsAddingNewOne: false,
      employeeDialogNameBeforeEdit: "",
      employeeDialogErrorMsg: null,
    };
  },
  methods: {
    changeGroupColor(color) {
      this.$store.commit("changeGroupColor", {
        groupName: this.colorEditGroup,
        newColor: color,
      });
      this.colorEditorDialog = false;
    },
    colorEditorDialogOpen(groupName) {
      this.colorEditGroup = groupName;
      this.colorEditorDialog = true;
    },
    removeEmployee(name) {
      this.$store.commit("removeEmployee", name);
    },
    employeeDialogStartAdd() {
      this.employeeDialog = true;
      this.employeeDialogGroupSelect = null;
      this.employeeDialogNameInput = "";
      this.employeeDialogIsAddingNewOne = true;
      this.employeeDialogErrorMsg = null;
    },
    employeeDialogStartEdit(emp) {
      this.employeeDialog = true;
      this.employeeDialogGroupSelect = emp.group;
      this.employeeDialogNameInput = emp.name;
      this.employeeDialogIsAddingNewOne = false;
      this.employeeDialogNameBeforeEdit = emp.name;
      this.employeeDialogErrorMsg = null;
    },
    employeeDialogConfirm() {
      // validation

      if (this.employeeDialogGroupSelect === null) {
        this.employeeDialogErrorMsg = "Wybierz grupę";
        return;
      }

      if (this.employeeDialogNameInput === "") {
        this.employeeDialogErrorMsg = "Wpisz imię";
        return;
      }

      if (
        this.$store.getters.doesEmployeeExistByName(
          this.employeeDialogNameInput
        )
      ) {
        this.employeeDialogErrorMsg = "Te imię jest już zajęte";
        return;
      }

      // execution

      if (this.employeeDialogIsAddingNewOne) {
        this.$store.dispatch("addEmployee", {
          group: this.employeeDialogGroupSelect,
          name: this.employeeDialogNameInput,
        });
      } else {
        this.$store.commit("updateEmployee", {
          name: this.employeeDialogNameBeforeEdit,
          newName: this.employeeDialogNameInput,
          newGroup: this.employeeDialogGroupSelect,
        });
      }

      this.employeeDialogErrorMsg = null;
      this.employeeDialog = false;
      this.employeeDialogGroupSelect = null;
      this.employeeDialogNameInput = "";
    },
    removeGroup(groupName) {
      if (
        confirm(
          "Czy jesteś pewien? Wszyscy pracownicy z grupy zostaną usunięci"
        )
      ) {
        this.$store.commit("removeGroup", groupName);
      }
    },
    addNewGroup() {
      this.$store.commit("addNewGroup", "nowa grupa");
    },
    changeGroupName(original, newVal) {
      this.editedGroup = null;

      if (this.$store.getters.doesGroupExistByName(newVal)) {
        alert("Ta nazwa grupy już istnieje");
        return;
      }

      if (newVal === original || newVal === "" || newVal === null) {
        return;
      }

      this.$store.commit("changeGroupName", {
        original: original,
        newName: newVal,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
