import moment from "moment";
import { TimeHelper } from "@/data/timeHelper";

export class Event {
  employeeName!: string;
  start!: string;
  end!: string;
  date!: string;

  // most of the code will be working with simple JS objects (not a Event class instance)
  // it is easier to assume that we are working only with simple objects
  // and pass them to static methods here.
  // When it is simple object (based on Event schema) it does not have prototype set
  // and it does not have instance methods.


  static ISOWEEK_CACHE: {[date: string]: number} = {}

  static isoWeek(event: Event): number {
    let isoweekCacheLabel = event.date;
    if (Event.ISOWEEK_CACHE[isoweekCacheLabel]) {
      return Event.ISOWEEK_CACHE[isoweekCacheLabel];
    }

    let week = moment(event.date).isoWeek();

    Event.ISOWEEK_CACHE[isoweekCacheLabel] = week;

    return week;
  }

  static DURATION_CACHE: {[hoursLabel: string]: number}= {}
  static durationHours(event: Event) {

    let durationCacheLabel = event.start + "-" + event.end
    if (Event.DURATION_CACHE[durationCacheLabel]) {
      return Event.DURATION_CACHE[durationCacheLabel];
    }

    let startMoment = TimeHelper.timeToMoment(event.start);
    let endMoment = TimeHelper.timeToMoment(event.end);

    if (endMoment.isBefore(startMoment)) {
      endMoment.add(1, "day");
    }

    let diff = endMoment.diff(startMoment, "hours", true);

    // rounding
    diff = +diff.toFixed(2);

    Event.DURATION_CACHE[durationCacheLabel] = diff;

    return diff;
  }

  static isValidHour(input: string) {
    let regexp = /^(([0-1]?[0-9]|2[0-3])(:[0-5][0-9])?|24)$/;
    return regexp.test(input);
  }

  static doEventsOverlap(event1: Event, event2: Event) {
    let a_start = TimeHelper.timeToMoment(event1.start);
    let a_end = TimeHelper.timeToMoment(event1.end);
    let b_start = TimeHelper.timeToMoment(event2.start);
    let b_end = TimeHelper.timeToMoment(event2.end);

    if (a_start.isSameOrBefore(b_start) && b_start.isSameOrBefore(a_end))
      return true; // b starts in a
    if (a_start.isSameOrBefore(b_end) && b_end.isSameOrBefore(a_end))
      return true; // b ends in a
    if (b_start.isBefore(a_start) && a_end.isBefore(b_end)) return true; // a in b
    return false;
  }

  static eventMonth(ev: Event) {
    let s = ev.date.substring(5,7).replace('0','');
    return Number(s);
  }
}
