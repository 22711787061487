import Amplify from '@aws-amplify/core';

let hostname = "";

if (location && location.hostname && typeof location.hostname === "string") {
  hostname = location.hostname;
}

let env;

if (hostname && (hostname.includes("localhost") || hostname.startsWith("192"))) {
  if (hostname === "localhost" || hostname.startsWith("192")) {
    console.log("ENV dev");
    env = "dev";
  } else if (hostname === "localhost-grafu-prod") {
    env = "production";
  } else {
    console.log("DID NOT RECOGNIZE HOSTNAME " + hostname);
    env = "dev";
  }
} else {
  env = "production";
}

console.log("HOSTNAME", hostname);
console.log("ENV", env);

let amplifyDev = {
  Auth: {
    region: "eu-central-1",

    // // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
    //
    //
    // // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "eu-central-1_e9n5c7vzj",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "vk12epc0dk0ngullvaapecvdi",

    // // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: false,

    // // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH'
  },
  API: {
    endpoints: [
      {
        name: "grafikiApi",
        endpoint:
          "https://j6vlc2r8pk.execute-api.eu-central-1.amazonaws.com/dev",
      },
    ],
  },
};

let amplifyProd = {
  Auth: {
    region: "eu-central-1",
    userPoolId: "eu-central-1_WxKDoUkw4",
    userPoolWebClientId: "2ubpkoavernve92d9nmc19s8uc",
  },
  API: {
    endpoints: [
      {
        name: "grafikiApi",
        endpoint:
          "https://vk1ro9cytf.execute-api.eu-central-1.amazonaws.com/production",
      },
    ],
  },
};

export let AMPLIFY_CONFIG: any;

if (env === "dev") {
  AMPLIFY_CONFIG = amplifyDev;
  AMPLIFY_CONFIG = amplifyProd;
} else {
  AMPLIFY_CONFIG = amplifyProd;
}

Amplify.configure(AMPLIFY_CONFIG);
