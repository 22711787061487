<template>
  <div class="pa-2" style="background-color:white;">
    <v-simple-table>
      <tbody>
        <tr v-for="location in locations" :key="location.locationId">
          <td class="text-uppercase">
            <div v-if="editLocationId !== location.locationId">
              <v-btn text style="cursor: default">
                <!--              TODO R1 button to edit location name -->
                {{ location.name }}
              </v-btn>
              <v-icon
                class="ml-2"
                size="15"
                @click="editLocationStart(location.locationId, location.name)"
              >
                mdi-pencil-outline
              </v-icon>
            </div>
            <v-text-field
              v-else
              append-outer-icon="mdi-check"
              @click:append-outer="
                changeLocationName(location.locationId, editLocationNameInput)
              "
              :value="location.name"
              @input="editLocationNameInput = $event"
            />
          </td>
          <td align="center">
            <v-btn
              text
              style="cursor: default; text-transform: none !important;"
            >
              do {{ paidToFormat(location.paidTo) }}
            </v-btn>
          </td>
          <td align="right">
            <v-btn
              outlined
              color="blue darken-2"
              @click="chooseLocation(location.locationId)"
            >
              Wejdź
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-container class="pa-0">
      <v-row>
        <v-col>
          <v-btn outlined text color="blue darken-2" small to="/extend">
            Odnowienie / nowy lokal
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="text-center overline ">
        <v-col class="pa-1">
          {{userEmail}}&nbsp;&nbsp;<router-link to="logout">wyloguj</router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { getUserEmail } from "@/services/authClient";

export default {
  name: "LocationList",
  computed: {
    locations() {
      return this.$store.state.locations;
    },
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    changeLocationName(locationId, newName) {
      this.editLocationId = null;

      this.$store.dispatch("changeLocationName", {
        locationId: locationId,
        newName: newName,
      });
    },
    editLocationStart(locationId, currentName) {
      this.editLocationId = locationId;
      this.editLocationNameInput = currentName;
    },
    chooseLocation(locationId) {
      this.$emit("locationChosen");
      console.log("chooseLocation", locationId);
      this.$store.dispatch("chooseLocation", locationId);
      this.showInternal = false;
    },
    paidToFormat(date) {
      return moment(date).format("DD.MM");
    },
  },
  props: {
    show: Boolean,
  },
  async created() {
    this.userEmail = await getUserEmail();
  },
  data() {
    return {
      showInternal: false,
      editLocationId: null,
      editLocationNameInput: "",
      userEmail: "email",
    };
  },
  watch: {
    show(newVal) {
      this.showInternal = newVal;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
  text-decoration: none;
}
</style>
