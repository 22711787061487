<template>
  <div
    ref="gifplayer"
    class="gifplayer-wrapper"
    :style="gifStyle"
    style="max-width:100%"
  >
    <div v-if="!isPlaying" class="gifplayer-preview">
      <img class="gifplayer" :src="src" @load="load" style="max-width: 100%" />
      <div v-if="pending" class="spinner"></div>
      <ins v-else class="play-gif" @click="playGif">▶</ins>
    </div>
    <div v-else class="gifplayer-element">
      <img
        class="gif-element"
        :src="gifSrc"
        @click="stopGif"
        style="max-width: 100%"
      />
    </div>
  </div>
</template>

<script>
const supportFormats = ["gif", "jpeg", "jpg", "png"];

export default {
  name: "gifplayer",
  props: {
    src: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: "GIF",
    },
    playOn: {
      type: String,
      required: false,
      default: "click",
    },
    onPlay: {
      type: Function,
      default: () => {},
    },
    onStop: {
      type: Function,
      default: () => {},
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    onLoad: {
      type: Function,
      default: () => {},
    },
    onLoadComplete: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isPlaying: false,
      gifStat: "pending",
      gifSrc: this.src,
      gifStyle: {},
    };
  },
  computed: {
    pending() {
      return this.gifStat === "pending";
    },
    loaded() {
      return this.gifStat === "loaded";
    },
  },
  mounted() {
    setInterval(() => {
      this.gifStat = "init";
    }, 2000)
  },
  methods: {
    playGif() {
      this.onClick();
      this.loadGif();
    },
    loadGif() {
      if (this.gifStat === "loaded") {
        this.isPlaying = true;
      } else {
        this.gifStat = "pending";
        this.preLoadGif(this.getFile("gif"));
      }
    },
    preLoadGif(src) {
      const gifImg = new Image();
      gifImg.src = src;
      gifImg.onload = () => {
        this.gifStat = "loaded";
        this.gifSrc = src;
        this.isPlaying = true;
      };
      gifImg.onerror = () => {
        console.log("error");
      };
    },
    stopGif() {
      this.isPlaying = false;
      this.onStop();
    },
    getFile(ext) {
      let gifSrc = this.gifSrc;
      for (let i = 0; i < supportFormats.length; i++) {
        const pattrn = new RegExp(supportFormats[i] + "$", "i");
        gifSrc = gifSrc.replace(pattrn, ext);
      }
      return gifSrc;
    },
    load() {
      const gifplayerWrapper = this.$refs.gifplayer;
      const gifplayer = gifplayerWrapper.getElementsByClassName("gifplayer")[0];
      this.gifStyle = {
        width: gifplayer.width + "px",
      };
    },
  },
  destroyed() {},
};
</script>

<style>
.gifplayer-wrapper {
  position: relative;
}

.play-gif {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -25px;
  margin-top: -25px;
  font-family: Arial, sans serif;
  width: 50px;
  height: 50px;
  line-height: 52px;
  text-align: center;
  font-size: 88px;
  color: #bfb8b8;
  opacity: 0.9;
  cursor: pointer;
  text-decoration: none;
  caret-color: transparent;
}

.play-gif:hover {
  opacity: 0.5;
}

.spinner {
  height: 50px;
  width: 50px;
  margin: 0px auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(98, 98, 98, 0.15);
  border-right: 6px solid rgba(98, 98, 98, 0.15);
  border-bottom: 6px solid rgba(98, 98, 98, 0.15);
  border-top: 6px solid rgba(98, 98, 98, 0.15);
  border-radius: 100%;
  caret-color: transparent;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
