import Auth from "@aws-amplify/auth";

import { centralMockConfig } from "../../config/mockConfig";

let authMock = centralMockConfig;

export async function getUserEmail() {
  let user = await getCurrentUser();

  return user.attributes.email;
}

export async function getCurrentUser() {
  if (authMock) {
    return {};
  }

  let currentUser;

  try {
    currentUser = await Auth.currentAuthenticatedUser();
  } catch (e) {
    console.log("currentAuthenticatedUser error", e);
    currentUser = null;
  }

  console.log("currentUser", currentUser);
  return currentUser;
}

export async function signIn(email: string, password: string) {
  // this will throw exception if something wrong
  let user = await Auth.signIn(email, password);

  if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
    const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
    console.log(
      "NEW_PASSWORD_REQUIRED, requiredAttributes",
      requiredAttributes
    );
    user = await Auth.completeNewPassword(
      user, // the Cognito User Object
      password, // the new password
      // OPTIONAL, the required attributes
      {
        // email: "xxxx@example.com",
        // phone_number: "1234567890",
      }
    );
    console.log("completeNewPassword user", user);
  }

  console.log("signIn result", user);
  return signIn;
}

export async function signOut() {
  return Auth.signOut();
}

export async function getUserId() {
  let idToken = (await getCurrentUser())["attributes"]["sub"];
  return idToken;
}

export async function getIdToken() {
  let idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return idToken;
}

export async function isUserLoggedIn() {
  let currentUser = await getCurrentUser();
  let isUserLogged = currentUser !== null;

  console.log("isUserLoggedIn check", currentUser, isUserLogged);
  return isUserLogged;
}
