import Vue from "vue";
import Vuex from "vuex";

import moment from "moment";
import _ from "lodash";
import apiClient from "../services/apiClient";
import { Employee, Group } from "../data/dataModel";
import { Event } from "@/data/event";
import { MonthEvents } from "@/data/monthEvents";
import { DayEvents } from "@/data/dayEvents";
import { Location } from "@/data/location";
import { TimeHelper } from "@/data/timeHelper";
import { softRounding } from "@/util/Numbers";

Vue.use(Vuex);

// todo extract set of methods for data creation and accessing properties

export const testedGetters = {
  eventsByEmployeeName: (state: State) => {
    var events = Object.values(state.eventsByDay)
      .flat();
    return _.groupBy(events, event => event.employeeName);
  },
};

export interface EventsByDay {
  [date: string]: Event[];
}

export interface State {
  // remote backed state - global
  locations: Location[];

  // current view state
  currentLocationId: string;
  currentMonth: number;
  eventsByDay: EventsByDay;
  groups: Group[];
  employees: Employee[];
  currentLocationStateVersion: number;
  currentDate: string;
  canModify: boolean;
  userId: string;

  // other local state
  activeEvent: Event | null;
  loading: boolean;
  saveInProgress: boolean;
  dirty: boolean;
  defaultStartForNewEvent: string;
  defaultEndForNewEvent: string;
  printGroups: string[];
}

let store = new Vuex.Store({
  strict: true,
  state: <State>{
    // global
    locations: [],

    // current view
    currentLocationId: "",
    currentMonth: moment().month() + 1,
    eventsByDay: {} as { [date: string]: Event[] },
    groups: [],
    employees: [],
    currentLocationStateVersion: 0,

    // TODO P3 will not change if app opened overnight
    currentDate: moment().format("YYYY-MM-DD"),
    canModify: false,
    userId: "",

    // other local state
    activeEvent: null,
    loading: true,
    saveInProgress: false,
    dirty: false,
    defaultStartForNewEvent: "8",
    defaultEndForNewEvent: "16",
    printGroups: [],
  },
  getters: {
    ...testedGetters,
    eventsByDayForPrint: (state, getters) => {
      return _.mapValues(state.eventsByDay, (dayEvents: Event[]) => {
        return dayEvents.filter(ev => {
          let groupName = getters.getEventGroupName(ev);
          return state.printGroups.includes(groupName);
        });
      });

    },
    mostCommonStartHours: (state) => {
      var allEvents = Object.values(state.eventsByDay).flat();

      var ret = _(allEvents)
        .countBy(ev => ev.start)
        .entries()
        .orderBy(_.last, "desc")
        .take(7)
        .map(_.head);
      // @ts-ignore
      return TimeHelper.sortHours(Array.from(ret));
    },
    mostCommonEndHours: (state) => {
      var allEvents = Object.values(state.eventsByDay).flat();

      var ret = _(allEvents)
        .countBy(ev => ev.end)
        .entries()
        .orderBy(_.last, "desc")
        .take(7)
        .map(_.head)
      ;
      // @ts-ignore
      return TimeHelper.sortHours(Array.from(ret));
    },
    weekNumbers: (state) => {
      return MonthEvents.getDisplayedWeekNumbers(state.currentMonth);
    },
    allEvents: (state) => {
      return Object.values(state.eventsByDay).flat();
    },
    getLocation: (state) => (id: string) => {
      let location = _.find(
        state.locations,

        function(location) {
          return location.locationId === id;
        },
      );
      return location;
    },
    getCurrentLocation: (state, getters) => {
      return getters.getLocation(state.currentLocationId);
    },
    employeesByGroupName: (state) => {
      let obj: { [groupName: string]: Employee[] } = {};
      state.groups.forEach((group) => {
        let employees = state.employees.filter(
          (emp) => emp.group === group.name,
        );
        obj[group.name] = employees;
      });
      return obj;
    },
    employees: (state, getters) => {
      // group-based get to return keep group ordering
      let ret = _.flatMap(state.groups, group => getters.employeesByGroupName[group.name]);
      return ret;
    },
    doesEmployeeExistByName: (state) => (name: string) => {
      let existingEmployee = state.employees.find(value => value.name === name);
      return existingEmployee !== undefined;
    },
    doesGroupExistByName: (state) => (name: string) => {
      let existingGroup = state.groups.find(g => g.name === name);
      return existingGroup !== undefined;
    },
    groupsByName: (state) => _.keyBy(state.groups, "name"),
    employeesByName: (state, getters) => _.keyBy(state.employees, "name"),
    eventsByWeekNum: (state) => {

      let events = Object.values(state.eventsByDay)
        .flat();

      return _.groupBy(events, event => {
        return Event.isoWeek(event);
      });
    },
    eventsByWeekNumAndGroupName: (state, getters) => (
      weekNum: number,
      groupName: string,
    ) => {
      return getters.eventsByWeekNum[weekNum].filter((event: Event) => {
        let employeeName = event.employeeName;
        var employee = getters.employeesByName[employeeName];
        var group = employee.group;
        return group === groupName;
      });
    },
    eventsByWeekNumAndEmployeeName: (state, getters) => (
      weekNum: string,
      name: string,
    ) => {
      return (getters.eventsByWeekNum[weekNum] || [])
        .filter((event: Event) => event.employeeName === name);
    },
    totalMonthHoursByGroupName: (state, getters) => {
      let ret = _.mapValues(getters.employeesByGroupName, (empList: Employee[]) => {
        let reduce = empList
          .map(emp => getters.totalMonthHoursByEmployeeName[emp.name])
          .reduce((a, b) => a + b, 0);
        return softRounding(reduce, 1);
      });
      return ret;
    },
    totalWeekHoursByGroupName: (state, getters) => (
      weekNum: number,
      name: string,
    ) => {
      let employeesByGroupName: Employee[] = getters.employeesByGroupName[name];

      let ret = employeesByGroupName
        .map((emp) => getters.eventsByWeekNumAndEmployeeName(weekNum, emp.name))
        .flat()
        .map((ev: Event) => Event.durationHours(ev))
        .reduce((a, b) => a + b, 0);
      return softRounding(ret, 1);
    },
    totalMonthHoursByEmployeeName: (state, getters) => {
      var events: Event[] = Object.values(state.eventsByDay).flat();

      // current month only
      events = events.filter((event: Event) => {
        let month = Event.eventMonth(event);
        return month === state.currentMonth;
      });

      let eventsByEmployee = _.groupBy(events, event => event.employeeName);

      let ret = _.mapValues(eventsByEmployee, events => {
        let ret = events.map(event => Event.durationHours(event))
          .reduce((a, b) => a + b, 0);
        return softRounding(ret, 1);
      });

      return ret;
    },
    getEventColor: (state, getters) => (event: Event) => {
      let employeeName = event.employeeName;
      const employee = getters.employeesByName[employeeName];
      if (employee) {
        return employee.color;
      } else {
        return "grey lighten-1";
      }
    },
    getEventGroupName: (state, getters) => (event: Event) => {

      const employee: Employee | undefined = getters.employeesByName[event.employeeName];

      if (!employee) {
        return "";
      }

      let group: Group | undefined = getters.groupsByName[employee.group];

      if (!group) {
        return "";
      }

      return group.name;
    },
  },
  mutations: {
    activeEventUpdate(state, payload) {
      let start = payload.start;
      let end = payload.end;

      if (state.activeEvent) {
        if (start) {
          state.activeEvent.start = start;
          state.defaultStartForNewEvent = start;
        }
        if (end) {
          state.activeEvent.end = end;
          state.defaultEndForNewEvent = end;
        }
      }

      // sort
      if (state.activeEvent) {
        let date = state.activeEvent.date;
        state.eventsByDay[date] = DayEvents.getSorted(
          state.eventsByDay[date],
          state.employees,
          state.groups,
        );
      }
    },
    setActiveEventAndCollapsePrevious(state, nextEvent) {
      let prevActiveEvent = state.activeEvent;
      state.activeEvent = nextEvent;

      // todo make this mutation an action and dispatch collapse async
      if (prevActiveEvent != null) {
        let collapseDate = prevActiveEvent.date;

        let dayEvents = state.eventsByDay[collapseDate];

        if (nextEvent == null) {
          // no focus, should collapse
          dayEvents = DayEvents.collapseEvents(prevActiveEvent, dayEvents);
        } else if (nextEvent.employeeName !== prevActiveEvent.employeeName) {
          // changed focus to other employee, can collapse prev employee events
          dayEvents = DayEvents.collapseEvents(prevActiveEvent, dayEvents);
        } else if (nextEvent.date !== collapseDate) {
          // changed focus to other day, can collapse prev day
          dayEvents = DayEvents.collapseEvents(prevActiveEvent, dayEvents);
        }

        state.eventsByDay[collapseDate] = dayEvents;
      }
    },
    removeEventFromDay(state, payload) {
      console.log("removeEventFromDay");
      let date = payload.date;
      // let event = payload.event;
      let index = payload.index;

      // remove
      let eventsList = state.eventsByDay[date];
      if (typeof eventsList !== "undefined") {
        // eventsList.splice(index, 1);
        let newList = eventsList.filter((ev, i) => i !== index);
        Vue.set(state.eventsByDay, date, newList);

        // force rerender
        // TODO P1 why is this needed?
        let newEventsByDay = { ...state.eventsByDay };
        state.eventsByDay = newEventsByDay;
      }
    },
    addEventToDay(state, payload) {
      console.log("addEventToDay");
      let date = payload.date;
      let event = payload.event;
      // let newIndex = payload.newIndex;

      event.date = date;

      // if new event (ie. not moved from other day)
      if (event.start == null) {
        event.start = state.activeEvent
          ? state.activeEvent.start
          : state.defaultStartForNewEvent;
        event.end = state.activeEvent
          ? state.activeEvent.end
          : state.defaultEndForNewEvent;
      }

      // add & sort

      let newList = [...state.eventsByDay[date]];
      newList.push(event);

      let sorted = DayEvents.getSorted(newList, state.employees, state.groups);
      state.eventsByDay[date] = sorted;

      // force rerender
      // TODO P1 why is this needed?
      let newEventsByDay = { ...state.eventsByDay };
      state.eventsByDay = newEventsByDay;
    },
    setupCurrentView(state, { locationId, month, savedState, optimisticLockVersion }) {
      if (!savedState) {
        savedState = {
          employees: [],
          groups: [],
          eventsByDay: {},
        };
      }

      let eventsByDay = MonthEvents.pickRelevantDays(
        savedState.eventsByDay,
        month,
      );
      MonthEvents.initializeMonthViewDays(eventsByDay, month);

      state.eventsByDay = eventsByDay;
      state.employees = savedState.employees;
      state.groups = savedState.groups;

      state.currentLocationStateVersion = optimisticLockVersion;
      state.currentLocationId = locationId;
      state.currentMonth = month;
    },
    setLocations(state, locations) {
      state.locations = locations;
    },
    changeGroupName(state, payload) {
      let original = payload.original;
      let newName = payload.newName;

      let group: Group | undefined = _.find(state.groups, ["name", original]);

      if (group) {
        let employees = state.employees;
        _.filter(employees, ["group", original]).forEach(
          (emp: Employee) => (emp.group = newName),
        );

        group.name = newName;
      }
    },
    addNewGroup(state, groupName) {
      var groupNameTaken = _.some(state.groups, ["name", groupName]);
      while (groupNameTaken) {
        groupName += "1";
        groupNameTaken = _.some(state.groups, ["name", groupName]);
      }
      state.groups.push({
        name: groupName,
        color: "blue",
      });
    },
    changeGroupColor(state, { groupName, newColor }) {
      let group = _.find(state.groups, ["name", groupName]);
      if (group) {
        group.color = newColor;

        state.employees = state.employees.map((emp) => {
          if (emp.group === groupName) {
            emp.color = newColor;
          }
          return emp;
        });
      }
    },
    removeGroup(state, groupName) {
      // remove events
      _.forIn(state.eventsByDay, (events, day) => {
        state.eventsByDay[day] = _.reject(events, (event) => {
          let employeeName = event.employeeName;
          let employee: Employee | undefined = _.find(state.employees, [
            "name",
            employeeName,
          ]);
          return employee !== undefined && employee.group === groupName;
        });
      });

      // remove employees
      state.employees = _.reject(state.employees, (emp) => {
        return emp.group === groupName;
      });

      // remove group
      state.groups = _.reject(state.groups, (gr) => gr.name === groupName);
    },
    addEmployee: (state, employee) => {
      state.employees.push(employee);
    },
    removeEmployee: (state, name) => {
      // remove events
      _.forIn(state.eventsByDay, (events, day) => {
        state.eventsByDay[day] = _.reject(events, (event) => {
          let employeeName = event.employeeName;
          return employeeName === name;
        });
      });

      state.employees = _.reject(state.employees, ["name", name]);
    },
    updateEmployee: (state, { name, newName, newGroup }) => {
      state.employees = state.employees.map((emp) => {
        if (emp.name === name) {
          emp.name = newName;
          let group = _.find(state.groups, ["name", newGroup]);
          if (group) {
            emp.color = group.color;
          }
          emp.group = newGroup;
        }
        return emp;
      });
    },
    // simple setters
    setLoading: (state, value) => (state.loading = value),
    setDirty: (state, value) => (state.dirty = value),
    setSaveInProgress: (state, save) => (state.saveInProgress = save),
    setPrintGroups: (state, val) => (state.printGroups = val),
    setCanModify: (state, val) => (state.canModify = val),
    setUserId: (state, val) => (state.userId = val),
  },
  actions: {
    async chooseMonth(context, month) {
      let currentLocationId = context.state.currentLocationId;
      await context.dispatch("chooseView", {
        locationId: currentLocationId,
        month: month,
      });
    },
    async chooseLocation(context, locationId) {
      let currentMonth = moment().month() + 1;
      await context.dispatch("chooseView", {
        locationId: locationId,
        month: currentMonth,
      });
    },
    async chooseView(context, { locationId, month }) {
      await context.dispatch("fetchLocations");

      let location = <Location>(
        _.find(context.state.locations, ["locationId", locationId])
      );

      console.log("Setting location", location);

      if (location) {
        context.commit("setupCurrentView", {
          locationId: locationId,
          month: month,
          savedState: location.savedState,
          optimisticLockVersion: location.version,
        });
      }
    },
    addEmployee(context, { name, group }) {
      const employee = {
        name: name,
        group: group,
        color: context.getters.groupsByName[group].color,
      };
      context.commit("addEmployee", employee);
    },
    async changeLocationName(
      context,
      {
        locationId,
        newName,
      }: {
        locationId: string;
        newName: string;
      },
    ) {
      let location = _.find(context.state.locations, [
        "locationId",
        locationId,
      ]);

      if (location) {
        location.name = newName;
        let result = await apiClient.updateLocation(locationId, null, null, newName);
        if (result) {
          context.state.currentLocationStateVersion++;
        }
      }
    },
    async pushLocationState(context) {
      context.commit("setSaveInProgress", true);

      let state = context.state;

      let locationId = state.currentLocationId;
      let optimisticLockVersion = state.currentLocationStateVersion;

      let result = await apiClient.updateLocation(
        locationId,
        optimisticLockVersion,
        {
          eventsByDay: state.eventsByDay,
          employees: state.employees,
          groups: state.groups,
        },
        null,
      );

      if (result) {
        console.log("Result true");
        context.commit("setDirty", false);
        state.currentLocationStateVersion++;
      }

      context.commit("setSaveInProgress", false);
    },
    async fetchLocations(context) {
      context.commit("setSaveInProgress", true);
      context.commit("setLoading", true);
      var locations = await apiClient.getAllLocations(context.state.userId);
      context.commit("setLocations", locations);
      context.commit("setSaveInProgress", false);

      setTimeout(function() {
        context.commit("setLoading", false);
      }, 1000);
    },
  },
});

// SYNCHRONIZATION WITH BACKEND

let throttledPush = _.debounce(async () => {
  if (!store.state.canModify) {
    return;
  }

  if (store.state.saveInProgress) {
    // retry later on
    throttledPush();
    return;
  }
  await store.dispatch("pushLocationState");
}, 2000);

// TODO possibly rewrite this to mutation subscribe and avoid push after fetch&setup

store.watch(
  (state, getters) => state.eventsByDay,
  (val) => {
    store.commit("setDirty", true);
    throttledPush();
  },
  { deep: true },
);

store.watch(
  (state, getters) => state.groups,
  (val) => {
    store.commit("setDirty", true);
    throttledPush();
  },
  { deep: true },
);

store.watch(
  (state, getters) => state.employees,
  (val) => {
    store.commit("setDirty", true);
    throttledPush();
  },
  { deep: true },
);

export default store;
