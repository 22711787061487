<template>
  <v-dialog :value="showInternal" persistent width="600">
    <v-card>
      <v-card-title class="title font-weight-bold ">
        Twoje lokale
      </v-card-title>
      <v-card-text class="pb-1" align="center">
        <LocationList v-if="!loading" @locationChosen="showInternal = false"></LocationList>
        <v-progress-circular
          v-else
          indeterminate
          color="amber"
          class="ma-8"
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import LocationList from "@/views/mainApp/partials/LocationList";

export default {
  components: {LocationList},
  name: "LocationPickDialog",
  computed: {
    locations() {
      return this.$store.state.locations;
    },
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    chooseLocation(locationId) {
      console.log("chooseLocation", locationId);
      this.$store.dispatch("chooseLocation", locationId);
      this.showInternal = false;
    },
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      showInternal: false,
    };
  },
  watch: {
    show(newVal) {
      this.showInternal = newVal;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
