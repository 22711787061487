











































import { signIn } from "@/services/authClient";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class PageLogin extends Vue {
  authInProgress: boolean = false;
  email: string = "";
  password: string = "";
  errorMsg: string = "";

  async startAuth() {
    this.authInProgress = true;

    this.email = this.email.trim();
    this.password = this.password.trim();

    try {
      await signIn(this.email, this.password);
      await this.$router.push("/app");
    } catch (e) {
      console.log(e);
      if (e.message === "User does not exist.") {
        alert("Nieprawidłowy login.");
      } else if (e.message === "Incorrect username or password.") {
        alert("Nieprawidłowy login lub hasło.");
      } else {
        this.errorMsg = e;
      }
    }
    this.authInProgress = false;
  }
}
