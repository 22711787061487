<template>
  <v-dialog :value="showInternal" persistent width="600">
    <v-card>
      <v-card-title class="font-weight-bold ">
        Grafik pracownika
      </v-card-title>
      <v-card-text class="pb-1" align="center">
        <div v-if="!loading">
          <v-radio-group v-model="employee">
            <v-radio
              v-for="emp in employees"
              :key="emp.name"
              :label="emp.name"
              :value="emp.name"
            ></v-radio>
          </v-radio-group>
        </div>
        <v-progress-circular
          v-else
          indeterminate
          color="amber"
          class="ma-8"
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import LocationList from "@/views/mainApp/partials/LocationList";

export default {
  components: {LocationList},
  name: "EmployeeChooseDialog",
  computed: {
    locations() {
      return this.$store.state.locations;
    },
    loading() {
      return this.$store.state.loading;
    },
    employees() {
      return this.$store.state.employees;
    },
  },
  methods: {
  },
  data() {
    return {
      showInternal: true,
      employee: null,
    };
  },
  watch: {
    show(newVal) {
      this.showInternal = newVal;
    },
    employee(val) {
      this.$emit("employeeChosen", val);
      this.showInternal = false;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
