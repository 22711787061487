import Vue from "vue";
import Router from "vue-router";
// @ts-ignore
import PageMainApp from "./views/PageMainApp.vue";
// @ts-ignore
import PageLogin from "./views/PageLogin";
import PageBuy from "./views/PageBuy.vue";
import PageIndex from "@/views/PageIndex.vue";
import { isUserLoggedIn } from "@/services/authClient";
import PageExtend from "@/views/PageExtend.vue";
import PageThanks from "@/views/PageThanks.vue";
import apiClient from "@/services/apiClient";
import PageMobile from "@/views/PageMobile.vue";
import PageTerms from "@/views/PageTerms.vue";
import PageEmployeeView from "@/views/PageEmployeeView.vue"

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/app",
      name: "PageMainApp",
      component: PageMainApp,
      beforeEnter: async (to, from, next) => {
        if (await isUserLoggedIn()) {
          console.log("ACCESS CHECK OK main");

          if (isSmallScreen()) {
            console.log("small screen - probably mobile - redirect");
            next("/mobile");
            return;
          }

          next();
          return;
        }
        console.log("ACCESS CHECK FAILED main");
        next("/login");
      },
    },
    {
      path: "/view/:userId/:locationIdPrefix",
      name: "PageEmployeeView",
      component: PageEmployeeView,
      props: true,
    },
    {
      path: "/extend",
      name: "PageMainExtend",
      component: PageExtend,
      beforeEnter: async (to, from, next) => {
        if (await isUserLoggedIn()) {
          console.log("ACCESS CHECK OK main");
          next();
          return;
        }
        console.log("ACCESS CHECK FAILED main");
        next("/login");
      },
    },
    {
      path: "/logout",
      name: "Logout",
      beforeEnter: async (to, from, next) => {
        console.log("logout");
        await apiClient.signOut();
        next("/login");
      },
    },
    // NOT LOGGED
    {
      path: "/login",
      name: "PageLogin",
      component: PageLogin,
      beforeEnter: async (to, from, next) => {
        if (await isUserLoggedIn()) {

          if (isSmallScreen()) {
            console.log("small screen - probably mobile - redirect");
            next("/mobile");
            return ;
          }

          next("/app");
          return;
        }
        next();
      },
    },
    {
      path: "/thanks",
      name: "PageThanks",
      component: PageThanks,
    },
    {
      path: "/mobile",
      name: "PageMobile",
      component: PageMobile,
    },
    {
      path: "/buy",
      name: "PageBuy",
      component: PageBuy,
    },
    {
      path: "/terms",
      name: "PageTerms",
      component: PageTerms,
    },
    {
      path: "*",
      name: "Index",
      component: PageIndex,
    },
  ],
});

function isSmallScreen() {
  console.log("screen", screen);
  if (screen.width <= 760) {
    return true;
  } else {
    return false;
  }
}
