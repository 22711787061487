import moment from "moment";
import { Location } from "@/data/location";
import { EventsByDay } from "@/state/store";

// TODO R1 add much more data (more employees and events etc.)

let eventsByDayLoc1: EventsByDay = {};

let formatMomentToDate = (moment: moment.Moment) => {
  return moment.format("YYYY-MM-DD");
};

let start = moment().date(2);

for (let i = 0; i < 5; i++) {
  let hours = [3,9,11,20];

  let date: string = formatMomentToDate(start);

  eventsByDayLoc1[date] = [];

  hours.forEach((hour) => {
    let event = {
      start: hour.toString(),
      end: (hour + 1).toString(),
      employeeName: "Marek",
      date: date,
    };
    eventsByDayLoc1[date].push(event);
  });

  hours.forEach((hour) => {
    let event = {
      start: hour.toString(),
      end: (hour + 1).toString(),
      employeeName: "Ania",
      date: date,
    };
    eventsByDayLoc1[date].push(event);
  });

  start.add(1, "week");
}

// ===

let getAllLocations: Location[] = [
  {
    userId: "test",
    locationId: "123-123-333",
    name: "local 1",
    paidTo: moment()
      .add(1, "month")
      .date(2)
      .format("YYYY-MM-DD"),
    savedState: {
      employees: [
        {
          color: "green",
          name: "Marek",
          group: "Kuchnia",
        },
        {
          color: "green",
          name: "Tomek",
          group: "Kuchnia",
        },
        {
          color: "green",
          name: "Andrzej",
          group: "Kuchnia",
        },
        {
          color: "red",
          name: "Ania",
          group: "Bar",
        },
        {
          color: "red",
          name: "Zuza",
          group: "Bar",
        },
        {
          color: "red",
          name: "Kasia",
          group: "Bar",
        },
        {
          color: "blue",
          name: "Karol",
          group: "Zmywak",
        },
        {
          color: "blue",
          name: "Dawid Sł",
          group: "Zmywak",
        },
        {
          color: "blue",
          name: "Jarek",
          group: "Zmywak",
        },
      ],
      groups: [
        { name: "Kuchnia", color: "green" },
        { name: "Bar", color: "red" },
        { name: "Zmywak", color: "blue" },
      ],
      eventsByDay: eventsByDayLoc1,
    },
    version: 1,
  },
  {
    userId: "test",
    locationId: "123-123-123",
    name: "local 2",
    paidTo: moment()
      .subtract(2, "days")
      .format("YYYY-MM-DD"),
    savedState: {
      employees: [
        {
          color: "green",
          name: "marek",
          group: "Kuchnia",
        },
        {
          color: "red",
          name: "ania",
          group: "Bar",
        },
        {
          color: "red",
          name: "ania1",
          group: "Bar",
        },
      ],
      groups: [
        { name: "Kuchnia", color: "green" },
        { name: "Bar", color: "red" },
      ],
      eventsByDay: {
        "2019-09-03": [
          {
            start: "9",
            end: "12",
            employeeName: "marek",
            date: "2019-09-03",
          },
        ],
        "2019-09-04": [
          {
            start: "9",
            end: "12",
            employeeName: "ania",
            date: "2019-09-04",
          },
        ],
      },
    },
    version: 1,
  },
];

export default { locations: getAllLocations };
