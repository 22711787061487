import moment from "moment";

moment.locale("pl");

export class DateHelper {
  static labelForMonth(month: number) {
    return moment()
      .month(month - 1)
      .format("MMMM");
  }

  static labelForMonthWithYear(month: number) {
    return moment()
      .month(month - 1)
      .format("MMMM YYYY");
  }
}
